import endOfDay from 'date-fns/endOfDay'
import React, { useState, useRef, useEffect } from 'react'
import { List } from 'core/components/list'
import { onlyNumbers } from 'core/helpers/format'
import { useRefresh } from 'core/hooks/refresh'
import { Field } from 'core/components/form/field'
import { Select } from 'core/components/form/select'
import { useFilters } from 'core/hooks/filter'

import { Button } from 'core/components/button'
import { AsyncSelect } from 'core/components/form/async-select'
import { DateInput } from 'core/components/form/date-input'

import { convertQuery } from 'service/helpers'
import { forceDownload } from 'core/helpers/misc'
import { Switch } from 'core/components/form/switch'
import { BirthdateMask } from 'core/constants'
import { IntegerInput } from 'core/components/form/integer-input'

const columns = [
  { path: 'registration', title: 'Matricula' },
  { path: 'name', title: 'Nome' },
  { path: 'cpf', title: 'CPF', format: 'cpfcnpj' },
  { path: 'localDescription', title: 'Município' },
  { path: 'workPlaceDescription', title: 'Local de Trabalho' },
]
const GroupType = [
  { id: 0, name: 'Geral' },
  { id: 1, name: 'Local de Trabalho' },
  { id: 2, name: 'Função' },
  { id: 3, name: 'Bairro' },
  { id: 5, name: 'Urna ' },
]

export const ElectionList = ({ global, route, service, basename }) => {
  const [workplaces, setWorkplaces] = useState([])
  const { modal } = global
  const query = new URLSearchParams(route.location.search)

  const [downloading, setDownloading] = useState(false)
  const [dbFilters, setDbFilters] = useState({
    name: '',
    cpf: '',
    userLocalId: query.get('local') || '',
    workPlaceId: query.get('workplace') || '',
    groupLocal: 0,
    electionAt: '',
    isSynthetic: false,
    isStreet: false,
    month: 6,

  })
  const queryRef = useRef({})
  let end = dbFilters.electionAt ? new Date(dbFilters.electionAt) : null

  end = end ? endOfDay(end.setMonth(end.getMonth() - dbFilters.month)) : null

  const { setValues, filters } = useFilters(dbFilters, query => [
    {
      items: [
        { name: 'name', value: query.name, comparer: 'Like' },
        { name: 'cpf', value: onlyNumbers(query.cpf), comparer: 'Like' },
        { name: 'userLocalId', value: query.userLocalId },
        { name: 'workPlaceId', value: query.workPlaceId },
        { name: 'affiliateDate', value: end, comparer: 'LessThanOrEqual' },
        { name: 'affiliate', value: true },
        { name: 'defaulter', value: false },
      ],
    },

  ])
  function handleXlsxDownload() {
    setDownloading(true)
    service
      .post(
        'election.export',
        { ...convertQuery(queryRef.current), type: 'xlsx', group: dbFilters.groupLocal, isSynthetic: dbFilters.isSynthetic },
        undefined,
        resp => resp.blob(),
      )
      .then(blob => forceDownload(blob, 'relatorio.xlsx'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }

  function handlePdfDownload() {
    setDownloading(true)
    service
      .post(
        'election.export',
        { ...convertQuery(queryRef.current), type: 'pdf', group: dbFilters.isStreet ? 4 : dbFilters.groupLocal, isSynthetic: dbFilters.isSynthetic, localId: dbFilters.userLocalId, workplace: dbFilters.workPlaceId },
        undefined,
        resp => resp.blob(),
      )
      .then(blob => forceDownload(blob, 'relatorio.pdf'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }
  const fetching = useState(false)
  const refresh = useRefresh()

  useEffect(() => {
    if (dbFilters.userLocalId) {
      service
        .getList('workplace', {
          filters: [
            {
              items: [
                {
                  name: 'WorkPlace.localId',
                  comparer: 'Equals',
                  value: dbFilters.userLocalId
                },
              ],
            },
          ],
          usePager: false,
        })
        .then(({ items }) => {
          if (items.length > 0) {
            setWorkplaces(items)
          } else {
            setDbFilters(prev => ({
              ...prev,
              workPlaceId: '',
            }))
            setWorkplaces([])
          }
        })
    } else {
      setDbFilters(prev => ({
        ...prev,
        workPlaceId: '',
      }))
      setWorkplaces([])
    }
    // eslint-disable-next-line
  }, [dbFilters.userLocalId])
  return (
    <>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fas fa-user-friends" />
            </span>
            <h3 className="kt-portlet__head-title">Lista para Eleição</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-pb-0 position-relative">
          <div className="row">
            <div className="col-lg">
              <Field label="Município">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ description }) => description}
                  getItems={value =>
                    service
                      .getList('local', {
                        sort: [['description', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'description',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.userLocalId}
                  onChange={local =>
                    setDbFilters(prev => ({
                      ...prev,
                      userLocalId: local ? local.id : '',
                    }))
                  }
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Locais de Trabalho">
                <Select
                  items={workplaces}
                  selected={dbFilters.workPlaceId}
                  getId={({ id }) => id}
                  getDisplay={({ description }) => description}
                  onChange={local =>
                    setDbFilters(prev => ({
                      ...prev,
                      workPlaceId: local ? local.id : '',
                    }))
                  }
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Agrupar Por">
                <Select
                  selected={dbFilters.groupLocal}
                  getDisplay={s => s.name}
                  getId={s => s.id}
                  items={GroupType}
                  onChange={groupLocal =>
                    setDbFilters(prev => ({
                      ...prev,
                      groupLocal: groupLocal.id,
                    }))
                  }
                  placeholder="Selecione um agrupamento"

                />
              </Field>
            </div>
            <div className="col-lg-1">
              <Field label={dbFilters.isSynthetic ? 'Sintético' : 'Analítico'}>
                <Switch
                  id="type"
                  checked={dbFilters.isSynthetic}
                  className="switch-layout"
                  onChange={isSynthetic =>
                    setDbFilters(prev => ({
                      ...prev,
                      isSynthetic,
                    }))
                  }
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label={'Com Endereço'}>
                <Switch
                  id="isStreet"
                  checked={dbFilters.isStreet}
                  className="switch-layout"
                  onChange={isStreet =>
                    setDbFilters(prev => ({
                      ...prev,
                      isStreet,
                    }))
                  }
                />
              </Field>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <Field label="Data da Eleição">
                <DateInput
                  customClassName="form-control-xl"

                  mask={BirthdateMask}
                  onChange={date =>
                    setDbFilters(prev => ({
                      ...prev,
                      electionAt: date,
                    }))
                  }
                  value={dbFilters.electionAt || null}
                />
              </Field>
            </div>
            <div className="col-lg-1">
              <Field label="Período">
                <IntegerInput
                  value={dbFilters.month}
                  onChange={month =>
                    setDbFilters(prev => ({
                      ...prev,
                      month,
                    }))
                  }
                />
              </Field>
            </div>
          </div>
          <div className="row">
            <div className="col-lg kt-align-right">
              <Field>
                <Button
                  customClassName="btn-dark btn-icon-sm margin-right-10"
                  icon="fas fa-file-excel"
                  onClick={handleXlsxDownload}
                  disabled={downloading}
                  title="Exportar XLSX"
                />
                <Button
                  customClassName="btn-dark btn-icon-sm margin-right-10"
                  icon="fas fa-file-pdf"
                  onClick={handlePdfDownload}
                  disabled={downloading}
                  title="Exportar PDF"
                />
                <Button
                  customClassName="btn-info btn-icon-sm"
                  icon="fas fa-search"
                  onClick={() => {
                    setValues(prev => ({
                      ...prev,
                      ...dbFilters,
                    }))
                  }}
                  title="Consultar"
                />
              </Field>
            </div>
          </div>

          {fetching[0] && (
            <div className="blockui-overlay">
              <div className="blockui" />
            </div>
          )}
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-separator kt-separator--space-sm" />

          <List
            queryRef={queryRef}
            primaryKey="id"
            modal={modal}
            route={route}
            refresh={refresh}
            fetching={fetching}
            filters={filters}
            initialQuery={{ fields: columns.map(c => c.path), perPage: 20, sort: [['localDescription', 'ASC'], ['name', 'ASC']], group: [['localDescription']] }}
            getItems={(query, signal) => service.getList('election', query, signal)}
            columns={columns}
          />
        </div>
      </div>
    </>
  )
}
