import endOfDay from 'date-fns/endOfDay'
import React, { useState, useRef } from 'react'
import { List } from 'core/components/list'
import { onlyNumbers } from 'core/helpers/format'
import { useRefresh } from 'core/hooks/refresh'
import { Field } from 'core/components/form/field'

import { useFilters } from 'core/hooks/filter'

import { Button } from 'core/components/button'
import { AsyncSelect } from 'core/components/form/async-select'


import { convertQuery } from 'service/helpers'
import { forceDownload } from 'core/helpers/misc'
import { Switch } from 'core/components/form/switch'



const columns = [
	{ path: 'registration', title: 'Matricula' },
	{ path: 'name', title: 'Nome' },
	//{ path: 'localDescription', title: 'Município' },
	{ path: 'workPlaceDescription', title: 'Local de Trabalho' },
	{ path: 'workFunctionDescription', title: 'Função' },
]

export const UserWorkList = ({ global, route, service, basename }) => {
	const { modal } = global
	const query = new URLSearchParams(route.location.search)

	const [downloading, setDownloading] = useState(false)
	const [dbFilters, setDbFilters] = useState({
		name: '',
		cpf: '',
		userLocalId: query.get('local') || '',
		workPlaceId: query.get('workplace') || '',
		groupLocal: false,
		end: endOfDay(new Date(new Date().setMonth(new Date().getMonth() - 6))),
    typeReport:false,
	})
	const queryRef = useRef({})
	const { setValues, filters } = useFilters(dbFilters, query => [
		{
			items: [
				{ name: 'name', value: query.name, comparer: 'Like' },
				{ name: 'cpf', value: onlyNumbers(query.cpf), comparer: 'Like' },
				{ name: 'workPlaceId', value: query.workPlaceId },
        { name: 'userLocalId', value: query.userLocalId },
        { name: 'workFunctionId', value: query.workFunctionId },

			],
		},
	])
	function handleXlsxDownload() {
		setDownloading(true)
		service
			.post(
				'userwork.export',
				{ ...convertQuery(queryRef.current), type: 'xlsx' },
				undefined,
				resp => resp.blob(),
			)
			.then(blob => forceDownload(blob, 'relatorio.xlsx'))
			.catch(err => modal.alert(err.message))
			.finally(() => setDownloading(false))
	}

	function handlePdfDownload() {
		setDownloading(true)
		service
			.post(
				'userwork.export',
				{ ...convertQuery(queryRef.current), type: 'pdf', typeReport: dbFilters.typeReport,  },
				undefined,
				resp => resp.blob(),
			)
			.then(blob => forceDownload(blob, 'relatorio.pdf'))
			.catch(err => modal.alert(err.message))
			.finally(() => setDownloading(false))
	}
	const fetching = useState(false)
	const refresh = useRefresh()


	return (
		<>
			<div className="kt-portlet kt-portlet--mobile">
				<div className="kt-portlet__head kt-portlet__head--lg">
					<div className="kt-portlet__head-label">
						<span className="kt-portlet__head-icon">
							<i className="kt-font-brand fas fa-user-friends" />
						</span>
						<h3 className="kt-portlet__head-title">
							Servidores por Local de trabalho</h3>
					</div>
				</div>
				<div className="kt-portlet__body kt-pb-0 position-relative">
          <div className="row">
          <div className="col-lg-2">
					<Switch
						id="typeReport"
						label={dbFilters.typeReport ? 'Sintético' : 'Analítico'}
						checked={dbFilters.typeReport}
						className={'switch-layout'}
            onChange={typeReport =>
              setDbFilters(prev => ({
                ...prev,
                typeReport
              }))}
					/>
				</div>
          </div>
          <br />
					<div className="row">
          <div className="col-lg">
              <Field label="Município">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ description }) => description}
                  getItems={value =>
                    service
                      .getList('local', {
                        sort: [['description', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'description',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.userLocalId}
                  onChange={local =>
                    setDbFilters(prev => ({
                      ...prev,
                      userLocalId: local ? local.id : '',
                    }))
                  }
                />
              </Field>
            </div>
						<div className="col-lg">
							<Field label="Locais de Trabalho">
								<AsyncSelect
									isClearable
									getId={({ id }) => id}
									getDisplay={({ description }) => description}
									getItems={value =>
										service
											.getList('workPlace', {
												sort: [['description', 'ASC']],
												filters: [
													{
														items: [
															{
																name: 'description',
																comparer: 'Like',
																value,
															},
														],
													},
												],
												usePager: false,
											})
											.then(({ items }) => items)
									}
									selected={dbFilters.workPlaceId}
									onChange={workPlace =>
										setDbFilters(prev => ({
											...prev,
											workPlaceId: workPlace ? workPlace.id : '',
										}))
									}
								/>
							</Field>
						</div>
            <div className="col-lg">
              <Field label="Funções">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ description }) => description}
                  getItems={value =>
                    service
                      .getList('workFunction', {
                        sort: [['description', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'description',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.workFunctionId}
                  onChange={workFunction =>
                    setDbFilters(prev => ({
                      ...prev,
                      workFunctionId: workFunction ? workFunction.id : '',
                    }))
                  }
                />
              </Field>
            </div>
					</div>

					<div className="row">
						<div className="col-lg kt-align-right">
							<Field>
								<Button
									customClassName="btn-dark btn-icon-sm margin-right-10"
									icon="fas fa-file-excel"
									onClick={handleXlsxDownload}
									disabled={downloading}
									title="Exportar XLSX"
								/>
								<Button
									customClassName="btn-dark btn-icon-sm margin-right-10"
									icon="fas fa-file-pdf"
									onClick={handlePdfDownload}
									disabled={downloading}
									title="Exportar PDF"
								/>
								<Button
									customClassName="btn-info btn-icon-sm"
									icon="fas fa-search"
									onClick={() => {
										setValues(prev => ({
											...prev,
											...dbFilters,
										}))
									}}
									title="Consultar"
								/>
							</Field>
						</div>
					</div>

					{fetching[0] && (
						<div className="blockui-overlay">
							<div className="blockui" />
						</div>
					)}
				</div>
				<div className="kt-portlet__body kt-portlet__body--fit">
					<div className="kt-separator kt-separator--space-sm" />

					<List
						queryRef={queryRef}
						primaryKey="id"
						modal={modal}
						route={route}
						refresh={refresh}
						fetching={fetching}
						filters={filters}
						initialQuery={{ fields: columns.map(c => c.path), perPage: 20, sort: [['workPlaceDescription', 'ASC'], ['workFunctionDescription', 'ASC'], ['name', 'ASC']] }}
						getItems={(query, signal) => service.getList('userwork', query, signal)}
						columns={columns}
					/>
				</div>
			</div>
		</>
	)
}
