import React, { useEffect } from 'react'
import { useForm } from 'core/hooks/form'
import { Field } from 'core/components/form/field'
import { TextInput } from 'core/components/form/text-input'
import { requiredMessage } from 'core/constants'
import { ErrorMessage } from 'core/components/form/error-message'
import { ModalForm } from 'core/components/modal'
import { modalSubmit } from 'helpers'
import { AsyncSelect } from 'core/components/form/async-select'

export const WorkplaceForm = ({ route, service, id, show, refresh }) => {
  const form = useForm(
    {
      displayName: ent => ent.description,
      initialEntity: {
        id: 0,
        description: '',
        localId: null,
        secretaryId: null,
      },
      validate: values => {
        const errors = {}
        if (!values.localId) errors.localId = requiredMessage
        if (!values.description) errors.description = requiredMessage
        else if (values.description.length > 50)
          errors.description = 'A descrição deve ter no máximo 50 caracteres.'
        return errors
      },
    },
    route,
  )

  const { entity, errors, touched } = form

  useEffect(() => {
    if (form.hasId) {
      form.handleFetch({
        action: (key, ac) => service.getById('workplace', key, ac.signal),
        errorFn: err => form.setErrors({ global: err.message }),
      })
    }
    // eslint-disable-next-line
  }, [id])

  return (
    <ModalForm
      isXLarge
      title={form.displayName || 'Novo Local de Trabalho'}
      show={show}
      fetching={form.fetching}
      submitting={form.submitting}
      resetForm={form.resetForm}
      closeAction={() => route.history.goBack()}
      onSubmit={() =>
        form.handleSubmit(data =>
          modalSubmit({ path: 'workplace', service, form, refresh, route, data }),
        )
      }
    >
      <div className="row">
        <div className="col-lg">
          <Field label="Descrição">
            <TextInput
              meta={{
                error: errors.description,
                touched: touched.description,
              }}
              value={entity.description}
              onChange={(description, type) =>
                form.handleChange({
                  path: 'description',
                  type,
                  values: prev => ({
                    ...prev,
                    description,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-lg">
          <Field label="Município">
            <AsyncSelect
              meta={{
                error: errors.localId,
                touched: touched.localId,
              }}
              isClearable
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              getItems={value =>
                service
                  .getList('local', {
                    sort: [['description', 'ASC']],
                    filters: [
                      {
                        items: [
                          {
                            name: 'description',
                            comparer: 'Like',
                            value,
                          },
                        ],
                      },
                    ],
                    usePager: false,
                  })
                  .then(({ items }) => items)
              }
              selected={entity.localId}
              onChange={local => {
                form.handleChange({
                  path: 'localId',
                  values: prev => ({
                    ...prev,
                    localId: local ? local.id : 0,
                  }),
                })
              }}
            />
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-lg">
          <Field label="Secretaria">
            <AsyncSelect
              meta={{
                error: errors.secretaryId,
                touched: touched.secretaryId,
              }}
              isClearable
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              getItems={() =>
                service
                  .getList('secretary', {
                    usePager: false,
                    sort: [['description', 'ASC']],
                    filters: [
                      {
                        items: [
                          {
                            name: 'localId',
                            comparer: 'Equals',
                            value: entity.localId,
                          },
                          {
                            name: 'disabledAt',
                            comparer: 'Equals',
                            value: null,
                          },
                        ],
                      },
                    ],
                  })
                  .then(({ items }) => items)
              }
              watchValues={[entity.localId]}
              selected={entity.secretaryId}
              onChange={secretary => {
                form.handleChange({
                  path: 'secretaryId',
                  values: prev => ({
                    ...prev,
                    secretaryId: secretary ? secretary.id : 0,
                  }),
                })
              }}
            />
          </Field>
        </div>
        <div className="col-lg-2">
          <Field label="Urna">
            <TextInput
              meta={{
                error: errors.ballot,
                touched: touched.ballot,
              }}
              value={entity.ballot}
              onChange={(ballot, type) =>
                form.handleChange({
                  path: 'ballot',
                  type,
                  values: prev => ({
                    ...prev,
                    ballot,
                  }),
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg-2">
          <Field label="Ordem">
            <TextInput
              meta={{
                error: errors.sort,
                touched: touched.sort,
              }}
              value={entity.sort}
              onChange={(sort, type) =>
                form.handleChange({
                  path: 'sort',
                  type,
                  values: prev => ({
                    ...prev,
                    sort,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>
      <div className="kt-heading kt-heading--sm">Endereço</div>
      <div className="row">
        <div className="col-lg">
          <Field label="Endereço">
            <TextInput
              meta={{
                error: errors.street,
                touched: touched.street,
              }}
              value={entity.street}
              onChange={(street, type) =>
                form.handleChange({
                  path: 'street',
                  type,
                  values: prev => ({
                    ...prev,
                    street,
                  }),
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Numero">
            <TextInput
              meta={{
                error: errors.number,
                touched: touched.number,
              }}
              value={entity.number}
              onChange={(number, type) =>
                form.handleChange({
                  path: 'number',
                  type,
                  values: prev => ({
                    ...prev,
                    number,
                  }),
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Bairro">
            <TextInput
              meta={{
                error: errors.district,
                touched: touched.district,
              }}
              value={entity.district}
              onChange={(district, type) =>
                form.handleChange({
                  path: 'district',
                  type,
                  values: prev => ({
                    ...prev,
                    district,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>

      <ErrorMessage error={errors.global} />
    </ModalForm>
  )
}
