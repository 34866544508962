import React, { useState, useEffect } from 'react'
import { Field } from 'core/components/form/field'
import { ErrorMessage } from 'core/components/form/error-message'
import { TextInput } from 'core/components/form/text-input'
import { Button } from 'core/components/button'
import { classNames } from 'core/helpers/misc'
import { Transition } from 'react-transition-group'
import { CpfMask, CellPhoneMask, KinshipDescription } from 'core/constants'
import { ModalPortal } from '../../core/components/modal'
import { DateInput } from 'core/components/form/date-input'
import { Select } from 'core/components/form/select'
import { isCPF } from 'core/helpers/validate'

export const DependentsModal = ({ service, show, onClose, onAdd, form }) => {
  const { errors, touched, entity } = form
  const { onEdit } = entity
  const [error] = useState(null)
  let [dependent, setDependent] = useState({
    name: '',
    cpf: '',
    birthDate: null,
    kinshipId: '',
    cellPhone: '',
    email: '',
  })

  useEffect(() => {
    if (onEdit) {
      setDependent({ ...onEdit })
    }
    // eslint-disable-next-line
  }, [form])

  return (
    <ModalPortal>
      <Transition in={show} timeout={300}>
        {status => (
          <>
            <div
              className={classNames('modal fade', { show: status === 'entered' })}
              style={{ display: status === 'exited' ? 'none' : 'block' }}
              tabIndex={-1}
              role="dialog"
              aria-modal="true"
            >
              <div role="document" className="modal-dialog modal-dialog-centered modal-xxl">
                <div className="modal-content">
                  <div className="kt-portlet__body">
                    <form
                      onSubmit={ev => {
                        ev.preventDefault()
                      }}
                    >
                      <div className="border border-top-0 rounded-bottom p-3">
                        <div className="modal-header">
                          <h5 className="modal-title">Dependente</h5>

                          <Button
                            type="button"
                            className="close"
                            aria-label="close"
                            data-dismiss="modal"
                            onClick={onClose}
                          />
                        </div>

                        <div className="modal-body ">
                          <div className="kt-portlet__body kt-portlet__body--fit">
                            <div className="row">
                              <div className="col-lg">
                                <Field label="Nome">
                                  <TextInput
                                    type="text"
                                    value={dependent.name}
                                    onChange={name => setDependent(prev => ({ ...prev, name }))}
                                  />
                                </Field>
                              </div>
                              <div className="col-lg">
                                <Field label="Data de Nasc.">
                                  <DateInput
                                    customClassName="form-control-xl"
                                    onChange={birthDate =>
                                      setDependent(prev => ({ ...prev, birthDate }))
                                    }
                                    value={dependent.birthDate || null}
                                  />
                                </Field>
                              </div>
                              <div className="col-lg">
                                <Field label="CPF">
                                  <TextInput
                                    mask={CpfMask}
                                    meta={{
                                      error: errors.dependentCpfCnpj,
                                      touched: !!dependent.cpf,
                                    }}
                                    value={dependent.cpf}
                                    onChange={cpf => {
                                      // dependent
                                      if (cpf && !isCPF(cpf)) {
                                        errors.dependentCpfCnpj = 'Digite um CPF válido'
                                      } else {
                                        delete errors.dependentCpfCnpj
                                      }

                                      setDependent(prev => ({ ...prev, cpf }))
                                    }}
                                  />
                                </Field>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg">
                                <Field label="Parentesco">
                                  <Select
                                    meta={{
                                      error: errors.kinshipId,
                                      touched: touched.kinshipId,
                                    }}
                                    maxMenuHeight={120}
                                    placement="top"
                                    getId={({ id }) => id}
                                    getDisplay={({ name }) => name}
                                    items={KinshipDescription}
                                    selected={dependent.kinshipId}
                                    onChange={kinship =>
                                      setDependent(prev => ({
                                        ...prev,
                                        kinshipId: kinship ? kinship.id : null,
                                        kinship,
                                      }))
                                    }
                                  />
                                </Field>
                              </div>
                              <div className="col-lg">
                                <Field label="Celular">
                                  <TextInput
                                    type="tel"
                                    icon="fas fa-phone"
                                    mask={CellPhoneMask}
                                    value={dependent.cellPhone}
                                    onChange={cellPhone =>
                                      setDependent(prev => ({
                                        ...prev,
                                        cellPhone,
                                      }))
                                    }
                                  />
                                </Field>
                              </div>
                              <div className="col-lg">
                                <Field label="E-mail">
                                  <TextInput
                                    type="email"
                                    icon="fas fa-envelope"
                                    value={dependent.email}
                                    onChange={email =>
                                      setDependent(prev => ({
                                        ...prev,
                                        email,
                                      }))
                                    }
                                  />
                                </Field>
                              </div>
                            </div>

                            <ErrorMessage error={error} />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <Button
                            type="button"
                            customClassName="btn-primary"
                            icon="fas fa-save"
                            title="Adicionar"
                            onClick={() => {
                              onAdd(dependent)
                              setDependent(prev => ({
                                ...prev,
                                name: '',
                                cpf: '',
                                birthDate: '',
                                kinshipId: '',
                                cellPhone: '',
                                email: '',
                              }))
                              if (onEdit) {
                                form.setValues(prev => ({ ...prev, onEdit: null }))
                                const dependents = [...entity.dependents]
                                dependents.splice(entity.i, 1)
                                form.setValues({
                                  dependents,
                                })
                              }
                            }}
                          />
                          <Button
                            type="button"
                            customClassName="btn-secondary"
                            icon="fas fa-arrow-left"
                            title="Voltar"
                            onClick={onClose}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {status !== 'exited' && (
              <div
                className={classNames('modal-backdrop fade', {
                  show: status === 'entered',
                })}
              />
            )}
          </>
        )}
      </Transition>
    </ModalPortal>
  )
}
