import { Button } from 'core/components/button'
import { Field } from 'core/components/form/field'
import { Select } from 'core/components/form/select'
import { TextInput } from 'core/components/form/text-input'
import { List } from 'core/components/list'
import { CivilStatusDescription } from 'core/constants'
import { forceDownload, iframeDownload } from 'core/helpers/misc'
import { useRefresh } from 'core/hooks/refresh'
import React, { useRef, useState } from 'react'
import { convertQuery } from 'service/helpers'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
const getRetireFormat = ({ retiredAt }) => {
  return retiredAt === null ? 'Não' : 'Sim'
}

const getCivilStatusFormat = ({ civilStatus }) =>
  CivilStatusDescription.find(status => status.id === civilStatus)?.name

const getAfilliateFormat = ({ disaffiliated }) => (disaffiliated ? 'Não' : 'Sim')
const getGenderFormat = ({ gender }) => {
  switch (gender) {
    case '1':
      return 'Feminino'
    case '2':
      return 'Masculino'
    default:
      return 'Outro'
  }
}

const allColumns = [
  { path: 'name', title: 'Nome' },
  { path: 'cpf', title: 'CPF', format: 'cpfcnpj' },
  { path: 'disaffiliated', title: 'Filiado', format: getAfilliateFormat },
  { path: 'registration', title: 'Matrícula', textAlign: 'center' },
  { path: 'rg', title: 'RG' },
  { path: 'email', title: 'E-mail' },
  { path: 'residencialPhone', title: 'Telefone Residencial', format: 'cellAndPhone' },
  { path: 'comercialPhone', title: 'Telefone Comercial', format: 'cellAndPhone' },
  { path: 'cellPhone', title: 'Celular', format: 'cellAndPhone' },
  { path: 'birthDate', title: 'Data de Nasc.', format: 'date' },
  { path: 'gender', title: 'Gênero', format: getGenderFormat },
  { path: 'civilStatus', title: 'Estado Civil', format: getCivilStatusFormat },
  { path: 'mother', title: 'Mãe' },
  { path: 'father', title: 'Pai' },
  { path: 'postalCode', title: 'CEP' },
  { path: 'streetName', title: 'Endereço' },
  { path: 'streetNumber', title: 'Número' },
  { path: 'complement', title: 'Complemento' },
  { path: 'district', title: 'Bairro' },
  { path: 'city', title: 'Cidade' },
  { path: 'state', title: 'Estado' },
  { path: 'localDescription', title: 'Município' },
  { path: 'secretaryDescription', title: 'Secretaria' },
  { path: 'workFunctionDescription', title: 'Função' },
  { path: 'workPlaceDescription', title: 'Local de Trabalho' },
  { path: 'retiredAt', title: 'Aposentado', format: getRetireFormat },
  { path: 'card', title: 'Cartão' },
  { path: 'affiliateDate', title: 'Data Filiação', format: 'date' },
  { path: 'disaffiliatedAt', title: 'Data Desfiliação', format: 'date' },
  { path: 'awayAt', title: 'Data Afastamento', format: 'date' },
  { path: 'exoneratedAt', title: 'Data Exoneração', format: 'date' },
]

const operators = [
  { id: 'Equals', name: 'Igual' },
  { id: 'NotEquals', name: 'Diferente' },
  { id: 'LessThan', name: 'Menor que' },
  { id: 'LessThanOrEqual', name: 'Menor que ou igual' },
  { id: 'GreaterThan', name: 'Maior que' },
  { id: 'GreaterThanOrEqual', name: 'Maior que ou igual' },
  { id: 'Like', name: 'Contém' },
  { id: 'Between', name: 'Entre' },
]

export const UserReport = ({ global, route, service }) => {
  const { modal } = global

  const queryRef = useRef({})

  const [configFilters, setConfigFilters] = useState([
    { id: 0, field: '', value: '', operator: 'Equals' },
    { id: 1, field: '', value: '', operator: 'Equals' },
    { id: 2, field: '', value: '', operator: 'Equals' },
  ])

  const [showColumns, setShowColumns] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [selectedColumns, setSelectedColumns] = useState([
    'cpf',
    'name',
    'localDescription',
    'retiredAt',
    'cellPhone',
  ])

  const [columns, setColumns] = useState(
    allColumns.filter(column => selectedColumns.includes(column.path)),
  )

  const fetching = useState(false)
  const refresh = useRefresh()

  function handleColumnCheck({ target }) {
    if (target.checked) {
      setSelectedColumns(previousColumns => [...previousColumns, target.id])
    } else {
      setSelectedColumns(previousColumns => previousColumns.filter(column => column !== target.id))
    }
  }

  function handleSubmit() {
    setColumns(allColumns.filter(column => selectedColumns.includes(column.path)))
    refresh.force()
  }

  function toggleShowColumns() {
    setShowColumns(prev => !prev)
  }

  function handleXlsxDownload() {
    setDownloading(true)
    service
      .post('user.export', { ...convertQuery(queryRef.current), type: 'xlsx' }, undefined, resp =>
        resp.blob(),
      )
      .then(blob => forceDownload(blob, 'relatorio.xlsx'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }

  function handlePdfDownload() {
    setDownloading(true)
    service
      .post('user.export', { ...convertQuery(queryRef.current), type: 'pdf' }, undefined, resp =>
        resp.blob(),
      )
      .then(blob => forceDownload(blob, 'relatorio.pdf'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }
  function handleTagDownload() {
    setDownloading(true)
    service
      .post('user.tag', { ...convertQuery(queryRef.current), type: 'pdf' }, undefined, resp =>
        resp.blob(),
      )
      .then(blob => iframeDownload(blob, 'Etiqueta.pdf'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }
  return (
    <>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fas fas fa-user" />
            </span>
            <h3 className="kt-portlet__head-title">Relatório de Servidores</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-pb-0 position-relative">
          {configFilters.map(configFilter => (
            <div key={configFilter.handleSubmit} className="row">
              <div className="col-lg">
                <Field label="Campo">
                  <Select
                    isClearable
                    items={allColumns}
                    getId={({ path }) => path}
                    getDisplay={({ title }) => title}
                    selected={configFilter.field}
                    onChange={column =>
                      setConfigFilters(prev =>
                        prev.map(item =>
                          item.id === configFilter.id
                            ? { ...item, field: column?.path || '' }
                            : item,
                        ),
                      )
                    }
                  />
                </Field>
              </div>

              <div className="col-lg">
                <Field label="Operador">
                  <Select
                    items={operators}
                    getId={({ id }) => id}
                    getDisplay={({ name }) => name}
                    selected={configFilter.operator}
                    onChange={operator =>
                      setConfigFilters(prev =>
                        prev.map(item =>
                          item.id === configFilter.id ? { ...item, operator: operator.id } : item,
                        ),
                      )
                    }
                  />
                </Field>
              </div>

              <div className="col-lg">
                <Field label="Valor">
                  <TextInput
                    ignoreBlur
                    type="search"
                    vaue={configFilter.value}
                    onChange={value =>
                      setConfigFilters(prev =>
                        prev.map(item =>
                          item.id === configFilter.id
                            ? {
                                ...item,
                                operator:
                                  item.field === 'affiliateDate' && item.operator === 'Equals'
                                    ? 'Between'
                                    : item.operator,
                                value:
                                  item.field === 'affiliateDate' && item.operator === 'Between'
                                    ? [
                                        startOfDay(
                                          new Date(
                                            value.split('/')[2],
                                            parseInt(value.split('/')[1]) - 1,
                                            value.split('/')[0],
                                          ),
                                        ),
                                        endOfDay(
                                          new Date(
                                            value.split('/')[2],
                                            parseInt(value.split('/')[1]) - 1,
                                            value.split('/')[0],
                                          ),
                                        ),
                                      ]
                                    : item.field === 'affiliateDate' ||
                                      (item.field === 'birthDate') & (item.operator !== 'Between')
                                    ? startOfDay(
                                        new Date(
                                          value.split('/')[2],
                                          parseInt(value.split('/')[1]) - 1,
                                          value.split('/')[0],
                                        ),
                                      )
                                    : value,
                              }
                            : item,
                        ),
                      )
                    }
                  />
                </Field>
              </div>
            </div>
          ))}

          <div className="row">
            <div className="col-lg">
              <div className="fields-check">
                <button className="btn btn-secondary" type="button" onClick={toggleShowColumns}>
                  <i class="fas fa-list"></i> Selecionar campos
                </button>

                {showColumns && (
                  <div className="boxes">
                    {allColumns.map(column => (
                      <div key={column.path}>
                        <input
                          type="checkbox"
                          id={column.path}
                          nome={column.path}
                          checked={selectedColumns.includes(column.path)}
                          onChange={handleColumnCheck}
                        />
                        <label htmlFor={column.path}>{column.title}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg kt-align-right">
              <Field>
                <Button
                  customClassName="btn-dark btn-icon-sm margin-right-10"
                  icon="fas fa-file-excel"
                  onClick={handleXlsxDownload}
                  disabled={downloading}
                  title="Exportar XLSX"
                />
                <Button
                  customClassName="btn-dark btn-icon-sm margin-right-10"
                  icon="fas fa-file-pdf"
                  onClick={handlePdfDownload}
                  disabled={downloading}
                  title="Exportar PDF"
                />
                <Button
                  customClassName="btn-dark btn-icon-sm margin-right-10"
                  icon="fas fa-file-pdf"
                  onClick={handleTagDownload}
                  disabled={downloading}
                  title="Gerar Etiqueta"
                />
                <Button
                  customClassName="btn-info btn-icon-sm"
                  icon="fas fa-search"
                  onClick={handleSubmit}
                  disabled={downloading}
                  title="Consultar"
                />
              </Field>
            </div>
          </div>

          {fetching[0] && (
            <div className="blockui-overlay">
              <div className="blockui" />
            </div>
          )}
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-separator kt-separator--space-sm" />
          <List
            dynamicQuery
            primaryKey="id"
            queryRef={queryRef}
            modal={modal}
            route={route}
            refresh={refresh}
            fetching={fetching}
            initialQuery={{
              perPage: 30,
              fields: columns.map(c => c.path),
              filters: [
                {
                  items: configFilters
                    .filter(filter => filter.field)
                    .map(filter => ({
                      name: filter.field,
                      comparer:
                        filter.field === 'retiredAt'
                          ? filter.value.toUpperCase() === 'SIM'
                            ? 'NotEquals'
                            : filter.operator
                          : filter.operator,
                      value:
                        filter.field === 'disaffiliated'
                          ? filter.value.toUpperCase() === 'SIM'
                            ? false
                            : true
                          : filter.field === 'retiredAt'
                          ? filter.value.toUpperCase() === 'SIM'
                            ? null
                            : null
                          : filter.value,
                    })),
                },
              ],
            }}
            getItems={(query, signal) => service.post('user.report', convertQuery(query), signal)}
            columns={columns}
          />
        </div>
      </div>
    </>
  )
}
