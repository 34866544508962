import React, { useState, useEffect } from 'react'
import { List } from 'core/components/list'
import { useRefresh } from 'core/hooks/refresh'
import { Field } from 'core/components/form/field'
import { TextInput } from 'core/components/form/text-input'
import { useFilters } from 'core/hooks/filter'
import { Button } from 'core/components/button'
import { ModalPortal } from 'core/components/modal'
import { Transition } from 'react-transition-group'
import { classNames } from 'core/helpers/misc'
import { CpfMask } from 'core/constants'
import { onlyNumbers } from 'core/helpers/format'
import { Switch } from 'core/components/form/switch'
import { toLocaleDateTime } from 'core/helpers/date'

const getKinshipFormat = ({ kinshipId }) => {
  return kinshipId ? KinshipDescription.find(Kinship => Kinship.id === kinshipId).name : 'Titular'
}
export const Kinship = {
  HOLDER: null,
  SPOUSE: 1,
  SON: 2,
  DAD: 3,
  MOTHER: 4,
  FATHERINLAW: 5,
  MOTHERINLAW: 6,
  SIBLING: 7,
  GRANDCHILD: 8,
  NEPHEW: 9,
  UNCLE: 10,
  GODSON: 11,
  FIANCE: 12,
  DEPENDENT: 13,
}

export const KinshipDescription = [
  { id: Kinship.HOLDER, name: 'Titular' },
  { id: Kinship.SPOUSE, name: 'Cônjuge' },
  { id: Kinship.SON, name: 'Filho' },
  { id: Kinship.DAD, name: 'Pai' },
  { id: Kinship.MOTHER, name: 'Mãe' },
  { id: Kinship.FATHERINLAW, name: 'Sogro' },
  { id: Kinship.MOTHERINLAW, name: 'Sogra' },
  { id: Kinship.SIBLING, name: 'Irmão(a)' },
  { id: Kinship.GRANDCHILD, name: 'Neto(a)' },
  { id: Kinship.NEPHEW, name: 'Sobrinho(a)' },
  { id: Kinship.UNCLE, name: 'Tio' },
  { id: Kinship.GODSON, name: 'Afilhado(a)' },
  { id: Kinship.FIANCE, name: 'Noivo(a)' },
  { id: Kinship.DEPENDENT, name: 'Dependente' },
]
const columns = [
  { path: 'id', title: '#', textAlign: 'center' },
  { path: 'cpf', title: 'CPF', format: 'cpfcnpj' },
  { path: 'name', title: 'Nome' },
  { path: 'kinshipId', title: 'Parentesco', format: getKinshipFormat },
  { path: 'adherent.name', title: 'Titular', format: a => a.adherent?.name || a.name },
]

const fields = [...columns.map(c => c.path), 'disabledAt']

export const disableUser = ({ modal, data: { id, name, disabledAt }, callback, service }) =>
  modal.confirm(
    `Deseja ${!disabledAt ? 'bloquear' : 'desbloquear'} ${name}?`,
    confirmed =>
      confirmed &&
      service
        .remove('club', id)
        .then(callback)
        .catch(err => modal.alert(err.message)),
  )

export const QueryUsers = ({ global, route, service, basename }) => {
  const { modal } = global
  const [parameters, setParameters] = useState({ group:0 })
  const [dbFilters, setDbFilters] = useState({
    name: '',
    cpf: '',
    modalShow: false,
    entity: {
      installments: [],
    },
  })

  const { setValues, filters } = useFilters({ name: '', cpf: '' }, query => [
    {
      items: [
        { name: 'CustomerClub.name', value: query.name, comparer: 'Like' },
      { name: 'CustomerClub.cpf', value: onlyNumbers(query.cpf), comparer: 'Like' }
    ],
    },
  ])

  const fetching = useState(false)
  const refresh = useRefresh()
  const [registers, setRegister] = useState([])
  useEffect(() => {
    if (dbFilters.id) {
      service
        .getList('registerclub', {
          filters: [
            {
              items: [
                {
                  name: 'userId',
                  comparer: 'Equals',
                  value: dbFilters.id,
                },
              ],
            },
          ],
          usePager: false,
        })
        .then(({ items }) => {
          if (items.length > 0) {
            setRegister(items)
            refresh.force()
          } else {
            setRegister([])
          }
        })
    }
    // eslint-disable-next-line
  }, [dbFilters.modalShow])
  return (
    <>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fas fa-user-md" />
            </span>
            <h3 className="kt-portlet__head-title">Consulta Associados</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper"></div>
          </div>
        </div>
        <div className="kt-portlet__body kt-pb-0 position-relative">
          <div className="row">
          <div className="col-lg-3">
              <Field label="CPF">
                <TextInput
                  ignoreBlur
                  type="search"
                  mask={CpfMask}
                  value={dbFilters.cpf}
                  onChange={cpf => setDbFilters(prev => ({ ...prev, cpf }))}
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Nome">
                <TextInput
                  ignoreBlur
                  type="search"
                  value={dbFilters.name}
                  onChange={name => setDbFilters(prev => ({ ...prev, name }))}
                />
              </Field>
            </div>
            <div className="col-lg-3">
              <Switch
                id="group"
                label="Exibir Dependentes"
                checked={parameters.group}
                className="button-add"
                onChange={group =>
                  setParameters(prev => ({
                    ...prev,
                    group: group === true ? 1 : 0,
                  }))
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg kt-align-right">
              <Field>
                <Button
                  customClassName="btn-info btn-icon-sm"
                  icon="fas fa-search"
                  onClick={() => {
                    setValues(prev => ({
                      ...prev,
                      ...dbFilters,
                    }))
                  }}
                  title="Consultar"
                />
              </Field>
            </div>
          </div>

          {fetching[0] && (
            <div className="blockui-overlay">
              <div className="blockui" />
            </div>
          )}
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-separator kt-separator--space-sm" />

          <List
            primaryKey="id"
            modal={modal}
            route={route}
            refresh={refresh}
            fetching={fetching}
            filters={filters}
            initialQuery={{ fields }}
            parameters= {parameters}
            getItems={(query, signal) => service.getList('club', query, signal)}
            actions={[
              {
                icon: 'fas fa-edit',
                title: 'Registrar Utilização',
                action: ({ entity }) => {
                  service
                    .addOrUpdate('registerclub', { userId: entity.id })
                    .then(() => refresh.force())
                  setDbFilters(prev => ({
                    ...prev,
                    modalShow: true,
                    id: entity.id,
                    nameQuery: entity.name,
                  }))
                },
              },
            ]}
            columns={columns}
          />
        </div>

        <ModalPortal>
          <Transition in={dbFilters.modalShow} timeout={300}>
            {status => (
              <>
                <div
                  className={classNames('modal fade', {
                    show: status === 'entered',
                  })}
                  style={{
                    display: status === 'exited' ? 'none' : 'block',
                  }}
                  tabIndex={-1}
                  role="dialog"
                  aria-modal="true"
                >
                  <div role="document" className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">{`Utilizações do Clube ${dbFilters?.nameQuery}`}</h5>

                        <Button
                          type="button"
                          className="close"
                          aria-label="close"
                          data-dismiss="modal"
                          onClick={() => {
                            setDbFilters(prev => ({ ...prev, modalShow: false }))
                          }}
                        />
                      </div>
                      <div className="modal-body">
                        <div className="kt-portlet__body kt-portlet__body--fit">
                          <div
                            className={classNames(
                              'report-irregular kt-datatable kt-datatable--default kt-datatable--brand',
                              {
                                'kt-datatable--loaded': registers.length > 0,
                              },
                            )}
                          >
                            <table className="kt-datatable__table">
                              <thead className="kt-datatable__head">
                                <tr className="kt-datatable__row">
                                  <th className="kt-datatable__cell">
                                    <span>Data da Utilização</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="kt-datatable__body">
                                {registers.map(i => (
                                  <tr key={i.id} className="kt-datatable__row">
                                    <td className="kt-datatable__cell">
                                      <div>{toLocaleDateTime(i?.createdAt)}</div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {registers.length === 0 && (
                              <div className="kt-datatable--error">Nenhum item foi encontrado.</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <Button
                          type="button"
                          customClassName="btn-secondary"
                          icon="fas fa-arrow-left"
                          title="Voltar"
                          onClick={() => {
                            setDbFilters(prev => ({ ...prev, modalShow: false }))
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {status !== 'exited' && (
                  <div
                    className={classNames('modal-backdrop fade', {
                      show: status === 'entered',
                    })}
                  />
                )}
              </>
            )}
          </Transition>
        </ModalPortal>
      </div>
    </>
  )
}
