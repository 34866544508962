import React, { useState, useRef } from 'react'
import { Select } from 'core/components/form/select'
import { List } from 'core/components/list'
import { Link } from 'core/components/route'
import { onlyNumbers } from 'core/helpers/format'
import { useRefresh } from 'core/hooks/refresh'
import { Field } from 'core/components/form/field'
import { TextInput } from 'core/components/form/text-input'
import { useFilters } from 'core/hooks/filter'
import { CpfMask } from 'core/constants'
import { Button } from 'core/components/button'
import { applyMask } from 'core/helpers/mask'
import { CellPhoneMask } from 'core/constants'
import { convertQuery } from 'service/helpers'
import { iframeDownload } from 'core/helpers/misc'
import { AsyncSelect } from 'core/components/form/async-select'

export const Kinship = {
  HOLDER: null,
  SPOUSE: 1,
  SON: 2,
  DAD: 3,
  MOTHER: 4,
  FATHERINLAW: 5,
  MOTHERINLAW: 6,
  SIBLING: 7,
  GRANDCHILD: 8,
  NEPHEW: 9,
  UNCLE: 10,
  GODSON: 11,
  FIANCE: 12,
  DEPENDENT: 13,
}

export const KinshipDescription = [
  { id: Kinship.HOLDER, name: 'Titular' },
  { id: Kinship.SPOUSE, name: 'Cônjuge' },
  { id: Kinship.SON, name: 'Filho' },
  { id: Kinship.DAD, name: 'Pai' },
  { id: Kinship.MOTHER, name: 'Mãe' },
  { id: Kinship.FATHERINLAW, name: 'Sogro' },
  { id: Kinship.MOTHERINLAW, name: 'Sogra' },
  { id: Kinship.SIBLING, name: 'Irmão(a)' },
  { id: Kinship.GRANDCHILD, name: 'Neto(a)' },
  { id: Kinship.NEPHEW, name: 'Sobrinho(a)' },
  { id: Kinship.UNCLE, name: 'Tio' },
  { id: Kinship.GODSON, name: 'Afilhado(a)' },
  { id: Kinship.FIANCE, name: 'Noivo(a)' },
  { id: Kinship.DEPENDENT, name: 'Dependente' },
]

const getWhatsAppFormat = ({ cellPhone }) => {
  if (!cellPhone) {
    return ''
  }

  return (
    <>
      <span>{applyMask(CellPhoneMask, cellPhone)}</span>
      <Link
        className="btn btn-sm"
        target="_blank"
        to={`http://api.whatsapp.com/send?1=pt_BR&phone=55${cellPhone}`}
      >
        <i style={{ color: 'green' }} className="fab fa-whatsapp" />
      </Link>
    </>
  )
}

const getKinshipFormat = ({ kinshipId }) => {
  return kinshipId ? KinshipDescription.find(Kinship => Kinship.id === kinshipId).name : 'Titular'
}

const columns = [
  { path: 'id', title: '#', textAlign: 'center' },
  { path: 'cpf', title: 'CPF', format: 'cpfcnpj' },
  { path: 'name', title: 'Nome' },
  { path: 'localDescription', title: 'Município' },
  { path: 'cellPhone', title: 'Celular', format: getWhatsAppFormat },
  { path: 'kinshipId', title: 'Parentesco', format: getKinshipFormat },
]

const fields = [...columns.map(c => c.path), 'disabledAt', 'whatsApp']

export const disableUser = ({ modal, data: { id, name, disabledAt }, callback, service }) =>
  modal.confirm(
    `Deseja ${!disabledAt ? 'bloquear' : 'desbloquear'} ${name}?`,
    confirmed =>
      confirmed &&
      service
        .remove('club', id)
        .then(callback)
        .catch(err => modal.alert(err.message)),
  )

export const ClubUserList = ({ global, route, service, basename }) => {
  const { modal } = global
  const query = new URLSearchParams(route.location.search)
  const queryRef = useRef({})
  const [parameters, setParameters] = useState({ group:0 })
  const [dbFilters, setDbFilters] = useState({
    name: '',
    cpf: '',
    kinshipId: '',
    holder: null,
    userLocalId: query.get('local') || '',
    secretaryId: query.get('secretary') || '',
    workPlaceId: query.get('workplace') || '',
    workFunctionId: query.get('function') || '',
  })

  const { setValues, filters } = useFilters(dbFilters, query => [
    {
      items: [
        { name: 'name', value: query.name, comparer: 'Like' },
        { name: 'cpf', value: onlyNumbers(query.cpf), comparer: 'Like' },
        { name: 'kinshipId', value: query.kinshipId, comparer: 'Equals' },
        query.holder === null
          ? {}
          : {
              name: 'kinshipId',
              value: null,
              comparer: query.holder === true ? 'Equals' : 'NotEquals',
            },
        { name: 'userLocalId', value: query.userLocalId },
        { name: 'secretaryId', value: query.secretaryId },
        { name: 'workPlaceId', value: query.workPlaceId },
        { name: 'workFunctionId', value: query.workFunctionId },
      ],
    },
  ])

  const fetching = useState(false)
  const refresh = useRefresh()
  const [downloading, setDownloading] = useState(false)
  const GroupType = [
    { id: 0, name: 'Geral' },
    { id: 1, name: 'Titular' },
  ]

  function handleTagDownload() {
    setDownloading(true)
    service
      .post('club.export', { ...convertQuery(queryRef.current), type: 'pdf', group: dbFilters.group }, undefined, resp =>
        resp.blob(),
      )
      .then(blob => iframeDownload(blob, 'usuariosClube.pdf'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }

  return (
    <>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fas fas fa-user" />
            </span>
            <h3 className="kt-portlet__head-title">Clube</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <div className="dropdown dropdown-inline">
                <Link className="btn btn-success btn-icon-sm" to={`${basename}/clube/cadastro`}>
                  <i className="fas fa-plus" /> Novo Usuário
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-pb-0 position-relative">
          <div className="row">
            <div className="col-lg-3">
              <Field label="CPF">
                <TextInput
                  ignoreBlur
                  type="search"
                  mask={CpfMask}
                  value={dbFilters.cpf}
                  onChange={cpf => setDbFilters(prev => ({ ...prev, cpf }))}
                />
              </Field>
            </div>

            <div className="col-lg-3">
              <Field label="Nome">
                <TextInput
                  ignoreBlur
                  type="search"
                  value={dbFilters.name}
                  onChange={name => setDbFilters(prev => ({ ...prev, name }))}
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Parentesco">
                <Select
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ name }) => name}
                  items={KinshipDescription}
                  selected={dbFilters.kinshipId}
                  onChange={kinship =>
                    setDbFilters(prev => ({
                      ...prev,
                      kinshipId: kinship ? kinship.id : '',
                      kinship,
                    }))
                  }
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Somente">
                <Select
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ name }) => name}
                  items={[
                    { id: null, name: 'Titular/Dependentes' },
                    { id: true, name: 'Titular' },
                    { id: false, name: 'Dependentes' },
                  ]}
                  selected={dbFilters.holder}
                  onChange={holder =>
                    setDbFilters(prev => ({
                      ...prev,
                      holder: holder ? holder.id : '',
                    }))
                  }
                />
              </Field>
            </div>
          </div>
          <div className="row">
            <div className="col-lg">
              <Field label="Município">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ description }) => description}
                  getItems={value =>
                    service
                      .getList('local', {
                        sort: [['description', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'description',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.userLocalId}
                  onChange={local =>
                    setDbFilters(prev => ({
                      ...prev,
                      userLocalId: local ? local.id : '',
                    }))
                  }
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Agrupar Por">
                <Select
                  selected={parameters.group}
                  getDisplay={s => s.name}
                  getId={s => s.id}
                  items={GroupType}
                  onChange={group =>
                    setParameters(prev => ({
                      ...prev,
                      group: group.id,
                    }))
                  }
                  placeholder="Selecione um agrupamento"
                />
              </Field>
            </div>

            {/* <div className="col-lg">
              <Field label="Secretaria">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ description }) => description}
                  getItems={value =>
                    service
                      .getList('secretary', {
                        sort: [['description', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'description',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.secretaryId}
                  onChange={secretary =>
                    setDbFilters(prev => ({
                      ...prev,
                      secretaryId: secretary ? secretary.id : '',
                    }))
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Locais de Trabalho">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ description }) => description}
                  getItems={value =>
                    service
                      .getList('workPlace', {
                        sort: [['description', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'description',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.workPlaceId}
                  onChange={workPlace =>
                    setDbFilters(prev => ({
                      ...prev,
                      workPlaceId: workPlace ? workPlace.id : '',
                    }))
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Funções">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ description }) => description}
                  getItems={value =>
                    service
                      .getList('workFunction', {
                        sort: [['description', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'description',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.workFunctionId}
                  onChange={workFunction =>
                    setDbFilters(prev => ({
                      ...prev,
                      workFunctionId: workFunction ? workFunction.id : '',
                    }))
                  }
                />
              </Field>
            </div> */}
          </div>
          <div className="row">
            <div className="col-lg kt-align-right">
              <Field>
                <Button
                  customClassName="btn-info btn-icon-sm margin-right-10"
                  icon="fas fa-search"
                  onClick={() => {
                    setValues(prev => ({
                      ...prev,
                      ...dbFilters,
                    }))
                  }}
                  title="Consultar"
                />
                <Button
                  customClassName="btn-dark btn-icon-sm "
                  icon="fas fa-file-pdf"
                  onClick={handleTagDownload}
                  disabled={downloading}
                  title="Exportar PDF"
                />
              </Field>
            </div>
          </div>

          {fetching[0] && (
            <div className="blockui-overlay">
              <div className="blockui" />
            </div>
          )}
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-separator kt-separator--space-sm" />

          <List
            primaryKey="id"
            modal={modal}
            queryRef={queryRef}
            route={route}
            refresh={refresh}
            fetching={fetching}
            filters={filters}
            initialQuery={{ fields }}
            parameters= {parameters}
            getItems={(query, signal) => service.getList('club', query, signal)}
            actions={[
              {
                icon: 'fas fa-edit',
                title: 'Editar',
                action: `${basename}/clube/:id`,
              },
              {
                icon: 'fas fa-lock',
                title: 'Bloquear',
                hideWhen: ent => !!ent.disabledAt,
                action: ({ entity, forceRefresh }) =>
                  disableUser({
                    callback: forceRefresh,
                    data: entity,
                    modal,
                    service,
                  }),
              },
              {
                icon: 'fas fa-unlock',
                title: 'Desbloquear',
                hideWhen: ent => !ent.disabledAt,
                action: ({ entity, forceRefresh }) =>
                  disableUser({
                    modal,
                    callback: forceRefresh,
                    data: entity,
                    service,
                  }),
              },
            ]}
            columns={columns}
          />
        </div>
      </div>
    </>
  )
}
