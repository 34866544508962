import React, { useRef, useEffect, useState } from 'react'
import { useForm } from 'core/hooks/form'
import {
  requiredMessage,
  CpfMask,
  CellPhoneMask,
  PhoneMask,
  BirthdateMask,
  CivilStatus,
  CivilStatusDescription,
  Gender,
  GenderDescription,
  KinshipDescription,
} from 'core/constants'
import { isCPF } from 'core/helpers/validate'
import { Field } from 'core/components/form/field'
import { TextInput } from 'core/components/form/text-input'
import { submit } from 'helpers'
import { isEmail } from 'core/helpers/validate'
import { Button } from 'core/components/button'
import { ErrorMessage } from 'core/components/form/error-message'
import { AddressForm } from '../address'
import { isObject } from 'core/helpers/misc'
import { classNames } from 'core/helpers/misc'
import { Select } from 'core/components/form/select'
import { Switch } from 'core/components/form/switch'
import { DateInput } from 'core/components/form/date-input'
import { DependentsModal } from './DependentsModal'
import { API } from 'service'
import { iframeDownload } from 'core/helpers/misc'
import { onlyNumbers } from 'core/helpers/format'
import { AsyncSelect } from 'core/components/form/async-select'

const findById = (items, selectId) => {
  const encontred = items.find(item => selectId === item.id)
  if (encontred) {
    return true
  } else {
    return false
  }
}

const RenderInitial = ({ form }) => {
  const { entity, errors, touched } = form
  const [loading, setLoading] = useState(false)
  const cpfRef = useRef()
  return (
    <>
      <div className="row">
        <div className="col-lg">
          <Field label="CPF">
            <TextInput
              meta={{
                error: errors.cpf,
                touched: touched.cpf,
                loading,
              }}
              mask={CpfMask}
              value={entity.cpf}
              onChange={(cpf, type) => {
                form.handleChange({
                  path: 'cpf',
                  type,
                  values: prev => ({
                    ...prev,
                    cpf,
                  }),
                })
                if (type === 'blur') {
                  setLoading(true)
                  API.getList('user', {
                    filters: [
                      {
                        items: [
                          {
                            name: 'cpf',
                            comparer: 'Equals',
                            value: onlyNumbers(entity.cpf),
                          },
                          {
                            name: 'disaffiliated',
                            comparer: 'Equals',
                            value: false,
                          },
                        ],
                      },
                    ],
                    usePager: false,
                  })
                    .then(({ items }) => {
                      if (items.length === 0) {
                        form.setValues({ notFound: true })
                      } else {
                        const { address } = items[0]
                        form.setValues({
                          name: items[0].name,
                          cpf: items[0].cpf,
                          rg: items[0].rg,
                          registration: items[0].registration,
                          email: items[0].email,
                          residencialPhone: items[0].residencialPhone,
                          cellPhone: items[0].cellPhone,
                          whatsApp: items[0].whatsApp,
                          birthDate: items[0].birthDate,
                          gender: items[0].gender,
                          civilStatus: items[0].civilStatus,
                          address,
                          userLocalId: items[0].userLocalId,
                          secretaryId: items[0].secretaryId,
                          workPlaceId: items[0].workPlaceId,
                          workFunctionId: items[0].workFunctionId,
                          admittedAt: items[0].admittedAt,
                          retiredAt: items[0].retiredAt,
                          awayAt: items[0].awayAt,
                          exoneratedAt: items[0].exoneratedAt,
                          changed: false,
                          notFound: false,
                        })
                      }

                      cpfRef.current.focus()
                    })
                    .catch(form.setValues({ notFound: true }))
                    .finally(() => setLoading(false))
                }
              }}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Nome">
            <TextInput
              meta={{
                error: errors.name,
                touched: touched.name,
              }}
              ref={cpfRef}
              value={entity.name}
              onChange={(name, type) =>
                form.handleChange({
                  path: 'name',
                  type,
                  values: prev => ({
                    ...prev,
                    name,
                  }),
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Rg">
            <TextInput
              type="rg"
              value={entity.rg}
              onChange={(rg, type) =>
                form.handleChange({
                  path: 'rg',
                  type,
                  values: prev => ({
                    ...prev,
                    rg,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>

      <div className="row">
        <div className="col-lg">
          <Field label="Matricula">
            <TextInput
              meta={{
                error: errors.registration,
                touched: touched.registration,
              }}
              mask={CpfMask}
              value={entity.registration}
              onChange={(registration, type) =>
                form.handleChange({
                  path: 'registration',
                  type,
                  values: prev => ({
                    ...prev,
                    registration,
                  }),
                })
              }
              // disabled={form.hasId}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="E-mail">
            <TextInput
              type="email"
              icon="fas fa-at"
              meta={{
                error: errors.email,
                touched: touched.email,
              }}
              value={entity.email}
              onChange={(email, type) =>
                form.handleChange({
                  path: 'email',
                  type,
                  values: prev => ({
                    ...prev,
                    email,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-lg">
          <Field label="Telefone Residencial">
            <TextInput
              type="tel"
              icon="fas fa-phone"
              mask={PhoneMask}
              value={entity.residencialPhone}
              onChange={(residencialPhone, type) =>
                form.handleChange({
                  path: 'residencialPhone',
                  type,
                  values: { residencialPhone },
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Celular">
            <TextInput
              type="tel"
              icon="fas fa-phone"
              mask={CellPhoneMask}
              value={entity.cellPhone}
              onChange={(cellPhone, type) =>
                form.handleChange({
                  path: 'cellPhone',
                  type,
                  values: { cellPhone },
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg-2">
          <Switch
            id="whatsapp"
            label="WhatsApp"
            checked={entity.whatsApp}
            className={'button-add switch-layout'}
            onChange={(whatsApp, type) => {
              form.handleChange({
                path: 'whatsApp',
                type,
                values: prev => ({
                  ...prev,
                  whatsApp,
                }),
              })
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg">
          <Field label="Data de Nasc.">
            <DateInput
              customClassName="form-control-xl"
              meta={{
                error: errors.birthDate,
                touched: touched.birthDate,
              }}
              mask={BirthdateMask}
              onChange={(birthDate, type) => {
                form.handleChange({
                  path: 'birthDate',
                  type,
                  values: prev => ({
                    ...prev,
                    birthDate,
                  }),
                })
              }}
              value={entity.birthDate || null}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Gênero">
            <Select
              meta={{
                error: errors.gender,
                touched: touched.gender,
              }}
              items={GenderDescription}
              selected={entity.gender}
              getId={({ id }) => id}
              getDisplay={({ name }) => name}
              onChange={gender => {
                form.handleChange({
                  path: 'workFunction',
                  values: prev => ({
                    ...prev,
                    gender: gender.id,
                  }),
                })
              }}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Estado Civil">
            <Select
              meta={{
                error: errors.civilStatus,
                touched: touched.civilStatus,
              }}
              items={CivilStatusDescription}
              selected={entity.civilStatus}
              getId={({ id }) => id}
              getDisplay={({ name }) => name}
              onChange={civilStatus => {
                form.handleChange({
                  path: 'workFunction',
                  values: prev => ({
                    ...prev,
                    civilStatus: civilStatus.id,
                  }),
                })
              }}
            />
          </Field>
        </div>
      </div>

      <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
      <div className="kt-heading kt-heading--sm">Endereço</div>

      <AddressForm
        address={entity.address}
        errors={errors}
        touched={touched}
        setValues={(property, value, type) => {
          if (isObject(property)) {
            form.handleChange({
              type: 'object',
              values: prev => ({
                ...prev,
                address: {
                  ...prev.address,
                  ...property,
                },
              }),
            })
          } else {
            entity.address[property] = value
            form.handleChange({
              path: property,
              type,
              values: prev => ({
                ...prev,
                address: entity.address,
              }),
            })
          }
        }}
      />

      <div className="row">
        <div className="col-lg-2">
          <Switch
            id="gift"
            label={entity.gift ? 'Brinde Entregue' : 'Brinde não Entregue'}
            checked={entity.gift}
            className={'button-add switch-layout'}
            onChange={(gift, type) => {
              form.handleChange({
                path: 'gift',
                type,
                values: prev => ({
                  ...prev,
                  gift,
                }),
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
const RenderDependet = ({ form }) => {
  const { entity } = form
  return (
    <>
      <div className="kt-portlet__head kt-portlet__head--lg">
        <div className="kt-portlet__head-label">
          <span className="kt-portlet__head-icon">
            <i className="kt-font-brand fas fas fa-user" />
          </span>
          <h3 className="kt-portlet__head-title">Dependentes</h3>
        </div>
        <div className="kt-portlet__head-toolbar">
          <div className="kt-portlet__head-wrapper">
            <div className="dropdown dropdown-inline">
              <Button
                icon="fas fa-plus"
                customClassName="btn-info btn-icon-sm"
                title="Adicionar Dependente"
                onClick={() => {
                  form.setValues(prev => ({
                    ...prev,
                    dependentModalShow: true,
                  }))
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row kt-mt-30">
        <div className="col-lg">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
            <table className="kt-datatable__table">
              <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                  <th className="kt-datatable__cell">
                    <span>Nome</span>
                  </th>
                  <th className="kt-datatable__cell">
                    <span>Parentesco</span>
                  </th>
                </tr>
              </thead>
              <tbody className="kt-datatable__body">
                {entity.dependents.map((dependent, i) => (
                  <tr key={i} className="kt-datatable__row">
                    <td className="kt-datatable__cell">
                      <div>{dependent.name}</div>
                    </td>
                    <td className="kt-datatable__cell">
                      <div>
                        {KinshipDescription.find(kinship => kinship?.id === dependent?.kinshipId)
                          .name || ''}
                      </div>
                    </td>
                    <td className="kt-datatable__cell">
                      <Button
                        customClassName="btn-sm kt-ml-10"
                        icon="fas fa-edit"
                        onClick={() => {
                          form.setValues(prev => ({ ...prev, dependentModalShow: true, i }))
                          form.handleChange({
                            path: 'onEdit',
                            values: {
                              onEdit: entity.dependents[i],
                            },
                          })
                        }}
                        title="Editar"
                      />
                      <Button
                        customClassName="btn-sm kt-ml-10"
                        icon="fas fa-trash"
                        onClick={() => {
                          const dependents = [...entity.dependents]
                          dependents.splice(i, 1)
                          form.handleChange({
                            path: 'dependents',
                            values: {
                              dependents,
                            },
                          })
                        }}
                        title="Excluir"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
const RenderAnother = ({ form, service }) => {
  const { entity, errors, touched } = form
  const [secretaries, setSecretaries] = useState([])
  const [workplaces, setWorkplaces] = useState([])
  const [workfunctions, setWorkfunctions] = useState([])

  useEffect(() => {
    if (entity.userLocalId) {
      service
        .getList('secretary', {
          filters: [
            {
              items: [
                {
                  name: 'localId',
                  comparer: 'Equals',
                  value: entity.userLocalId,
                },
                {
                  name: 'disabledAt',
                  comparer: 'Equals',
                  value: null,
                },
              ],
            },
          ],
          usePager: false,
        })
        .then(({ items }) => {
          if (items.length > 0) {
            setSecretaries(items)
            form.setValues(prev => ({
              ...prev,
              secretaryId: entity.secretaryId
                ? findById(items, entity.secretaryId)
                  ? entity.secretaryId
                  : items[0].id
                : items[0].id,
            }))
          } else {
            form.setValues(prev => ({
              ...prev,
              secretaryId: '',
              workPlaceId: '',
            }))
            setSecretaries([])
            setWorkplaces([])
          }
        })
    } else {
      form.setValues(prev => ({
        ...prev,
        secretaryId: '',
        workPlaceId: '',
      }))
      setSecretaries([])
      setWorkplaces([])
    }

    // eslint-disable-next-line
  }, [entity.userLocalId])

  useEffect(() => {
    if (entity.userLocalId) {
      service
        .getList('workplace', {
          filters: [
            {
              items: [
                {
                  name: 'WorkPlace.localId',
                  comparer: 'Equals',
                  value: entity.userLocalId,
                },
              ],
            },
          ],
          usePager: false,
        })
        .then(({ items }) => {
          if (items.length > 0) {
            setWorkplaces(items)
            form.setValues(prev => ({
              ...prev,
              workPlaceId: entity.workPlaceId
                ? findById(items, entity.workPlaceId)
                  ? entity.workPlaceId
                  : ''
                : items[0].id,
            }))
          } else {
            form.setValues(prev => ({
              ...prev,
              workPlaceId: '',
            }))
            setWorkplaces([])
          }
        })
    } else {
      form.setValues(prev => ({
        ...prev,
        workPlaceId: '',
      }))
      setWorkplaces([])
    }
    // eslint-disable-next-line
  }, [entity.userLocalId])

  useEffect(() => {
    if (entity.userLocalId) {
      service
        .getList('workfunction', {
          filters: [
            {
              items: [
                {
                  name: 'WorkFunction.localId',
                  comparer: 'Equals',
                  value: entity.userLocalId,
                },
              ],
            },
          ],
          usePager: false,
        })
        .then(({ items }) => {
          if (items.length > 0) {
            setWorkfunctions(items)
            form.setValues(prev => ({
              ...prev,
              workFunctionId: entity.workFunctionId
                ? findById(items, entity.workFunctionId)
                  ? entity.workFunctionId
                  : ''
                : items[0].id,
            }))
          } else {
            form.setValues(prev => ({
              ...prev,
              workFunctionId: '',
            }))
            setWorkfunctions([])
          }
        })
    } else {
      form.setValues(prev => ({
        ...prev,
        workFunctionId: '',
      }))
      setWorkfunctions([])
    }
    // eslint-disable-next-line
  }, [entity.userLocalId])

  return (
    <>
      <div className="row">
        <div className="col-lg">
          <Field label="Município">
            <AsyncSelect
              meta={{
                error: errors.userLocalId,
                touched: touched.userLocalId,
              }}
              isClearable
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              getItems={value =>
                service
                  .getList('local', {
                    filters: [
                      {
                        items: [
                          {
                            name: 'description',
                            comparer: 'Like',
                            value,
                          },
                        ],
                      },
                    ],
                    usePager: false,
                  })
                  .then(({ items }) => items)
              }
              selected={entity.userLocalId}
              onChange={local => {
                form.handleChange({
                  path: 'userLocalId',
                  values: prev => ({
                    ...prev,
                    userLocalId: local ? local.id : 0,
                  }),
                })
              }}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Secretaria">
            <Select
              meta={{
                error: errors.secretaryId,
                touched: touched.secretaryId,
              }}
              items={secretaries}
              selected={entity.secretaryId}
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              onChange={secretary => {
                form.handleChange({
                  path: 'secretary',
                  values: prev => ({
                    ...prev,
                    secretaryId: secretary.id,
                  }),
                })
              }}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Local de Trabalho">
            <Select
              meta={{
                error: errors.workPlaceId,
                touched: touched.workPlaceId,
              }}
              items={workplaces}
              selected={entity.workPlaceId}
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              onChange={workPlace => {
                form.handleChange({
                  path: 'workPlace',
                  values: prev => ({
                    ...prev,
                    workPlaceId: workPlace.id,
                  }),
                })
              }}
            />
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-lg">
          <Field label="Função">
            <Select
              meta={{
                error: errors.workFunctionId,
                touched: touched.workFunctionId,
              }}
              items={workfunctions}
              selected={entity.workFunctionId}
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              onChange={workFunction => {
                form.handleChange({
                  path: 'workFunction',
                  values: prev => ({
                    ...prev,
                    workFunctionId: workFunction.id,
                  }),
                })
              }}
            />
          </Field>
        </div>

        <div className="col-lg">
          <Field label="Data de Admissão">
            <DateInput
              customClassName="form-control-xl"
              meta={{
                error: errors.admittedAt,
                touched: touched.admittedAt,
              }}
              mask={BirthdateMask}
              onChange={(admittedAt, type) => {
                form.handleChange({
                  path: 'admittedAt',
                  type,
                  values: prev => ({
                    ...prev,
                    admittedAt,
                  }),
                })
              }}
              value={entity.admittedAt || null}
            />
          </Field>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3">
          <Field label="Data de Aposentadoria">
            <DateInput
              customClassName="form-control-xl"
              meta={{
                error: errors.retiredAt,
                touched: touched.retiredAt,
              }}
              mask={BirthdateMask}
              onChange={(retiredAt, type) => {
                form.handleChange({
                  path: 'retiredAt',
                  type,
                  values: prev => ({
                    ...prev,
                    retiredAt,
                  }),
                })
              }}
              value={entity.retiredAt || null}
            />
          </Field>
        </div>
        <div className="col-lg-3">
          <Field label="Data de Afastamento">
            <DateInput
              customClassName="form-control-xl"
              meta={{
                error: errors.awayAt,
                touched: touched.awayAt,
              }}
              mask={BirthdateMask}
              onChange={(awayAt, type) => {
                form.handleChange({
                  path: 'awayAt',
                  type,
                  values: prev => ({
                    ...prev,
                    awayAt,
                  }),
                })
              }}
              value={entity.awayAt || null}
            />
          </Field>
        </div>
        <div className="col-lg-3">
          <Field label="Data de Exoneração">
            <DateInput
              customClassName="form-control-xl"
              meta={{
                error: errors.exoneratedAt,
                touched: touched.exoneratedAt,
              }}
              disabled={!entity.disaffiliated}
              mask={BirthdateMask}
              onChange={(exoneratedAt, type) => {
                form.handleChange({
                  path: 'exoneratedAt',
                  type,
                  values: prev => ({
                    ...prev,
                    exoneratedAt,
                  }),
                })
              }}
              value={entity.exoneratedAt || null}
            />
          </Field>
        </div>
      </div>
    </>
  )
}
const RenderDocs = ({ service, route, modal, form }) => {
  const { entity } = form
  const [downloading, setDownloading] = useState(false)
  function handleConsentDownload() {
    setDownloading(true)
    service
      .post('clube.consent', { ...entity }, undefined, resp => resp.blob())
      .then(blob => iframeDownload(blob, 'Termo_de_Consentimento.pdf'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }
  return (
    <>
      <div className="kt-portlet__body kt-portlet__body--fit">
        <div className="kt-separator kt-separator--space-sm" />

        <ul>
          <li className="nav-item">
            <Button
              icon="fas fa-file-pdf"
              onClick={handleConsentDownload}
              disabled={downloading}
              title="TERMO DE CONSENTIMENTO"
            />
          </li>
        </ul>
      </div>
    </>
  )
}

export const ClubUserForm = ({ route, service, basename, global }) => {
  const { modal } = global
  const form = useForm(
    {
      displayName: ent => ent.name,
      initialEntity: {
        id: 0,
        name: '',
        cpf: '',
        email: '',
        rg: '',
        registration: '',
        residencialPhone: '',
        cellPhone: '',
        birthDate: null,
        civilStatus: CivilStatus.SINGLE,
        gender: Gender.FEMALE,
        whatsApp: false,
        //
        dependents: [],
        address: {
          postalCode: '',
          streetName: '',
          streetNumber: '',
          district: '',
          city: '',
          state: '',
          complement: '',
        },
        view: 'Initial',
        defaultDate: null,
        defaulter: false,
        basename,
        dependentModalShow: false,
        notFound: false,
        gift: false,
      },
      validate: values => {
        const errors = {}
        if (!values.name) errors.name = requiredMessage
        else if (values.name.length < 8) errors.name = 'O nome deve ter pelo menos 8 caracteres.'
        else if (values.name.length > 100) errors.name = 'O nome deve ter no máximo 100 caracteres.'

        if (!values.cpf) errors.cpf = requiredMessage
        else if (!isCPF(values.cpf)) errors.cpf = 'Digite um CPF válido.'

        if (values.email && !isEmail(values.email))
          errors.email = 'Digite um endereço de email válido.'

        if (values.notFound) errors.registration = 'Matricula não encontrada'

        return errors
      },
    },
    route,
  )

  const { entity, errors } = form
  const fetching = form.fetching || (form.hasId && entity.id === 0)
  useEffect(() => {
    if (form.hasId) {
      form.handleFetch({
        action: (id, ac) => service.getById('club', id, ac.signal),
        errorFn: err => form.setErrors({ global: err.message }),
      })
    }
    // eslint-disable-next-line
  }, [])
  return fetching ? (
    'Carregando...'
  ) : (
    <>
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              {form.displayName ? `Editar ${form.displayName}` : 'Novo Titular'}
            </h3>
          </div>
        </div>
        <form
          autoComplete="off"
          className="kt-form kt-form--label-right"
          onSubmit={ev => {
            ev.preventDefault()
            if (!form.hasId) {
              modal.confirm(`Deseja entregar o Brinde?`, confirmed => {
                if (confirmed) {
                  form.setValues(prev => ({ ...prev, gift: true }))
                  form.handleSubmit(data => {
                    submit({
                      path: 'club',
                      callback: () => route.history.push(`${basename}/clube`),
                      data: { ...data, gift: true },
                      service,
                      form,
                    })
                  })
                } else {
                  form.handleSubmit(data => {
                    submit({
                      path: 'club',
                      callback: () => route.history.push(`${basename}/clube`),
                      data,
                      service,
                      form,
                    })
                  })
                }
              })
            } else {
              ev.preventDefault()
              form.handleSubmit(data => {
                submit({
                  path: 'club',
                  callback: () => route.history.push(`${basename}/clube`),
                  data,
                  service,
                  form,
                })
              })
            }
          }}
        >
          <div className="kt-portlet__body">
            <ul className="nav nav-tabs kt-mb-0" role="tablist">
              <li className="nav-item">
                <button
                  className={classNames('nav-link', {
                    active: entity.view === 'Initial',
                  })}
                  onClick={() =>
                    form.setValues({
                      view: 'Initial',
                    })
                  }
                  type="button"
                >
                  <span className="btn kt-padding-0">Dados do Titular</span>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={classNames('nav-link', {
                    active: entity.view === 'Another',
                  })}
                  onClick={() =>
                    form.setValues({
                      view: 'Another',
                    })
                  }
                  type="button"
                >
                  <span className="btn kt-padding-0">Outros Dados</span>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={classNames('nav-link', {
                    active: entity.view === 'Dependent',
                  })}
                  onClick={() =>
                    form.setValues({
                      view: 'Dependent',
                    })
                  }
                  type="button"
                >
                  <span className="btn kt-padding-0">Dependentes</span>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={classNames('nav-link', {
                    active: entity.view === 'Docs',
                  })}
                  onClick={() =>
                    form.setValues({
                      view: 'Docs',
                    })
                  }
                  type="button"
                >
                  <span className="btn kt-padding-0">Documentos</span>
                </button>
              </li>
            </ul>
            <div className="border border-top-0 rounded-bottom p-3">
              {entity.view === 'Initial' && <RenderInitial form={form} />}
              {entity.view === 'Another' && <RenderAnother form={form} service={service} />}
              {entity.view === 'Dependent' && <RenderDependet form={form} />}
              {entity.view === 'Docs' && (
                <RenderDocs service={service} route={route} modal={modal} form={form} />
              )}
            </div>
            <br />

            <ErrorMessage error={errors.global} />
          </div>
          <div className="kt-portlet__foot">
            <div className="kt-form__actions">
              <div className="row">
                <div className="col-lg kt-align-right">
                  <Button
                    type="button"
                    icon="fas fa-arrow-left"
                    customClassName="btn-secondary"
                    title="Voltar"
                    disabled={form.submitting}
                    onClick={() => route.history.goBack()}
                  />
                  <Button
                    icon="fas fa-save"
                    customClassName="btn-primary"
                    title="Salvar"
                    loading={form.submitting}
                    disabled={form.submitting}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <DependentsModal
        form={form}
        service={service}
        show={entity.dependentModalShow}
        onClose={() => form.setValues(prev => ({ ...prev, dependentModalShow: false }))}
        onAdd={obj => {
          entity.dependents.push(obj)
          form.setValues(prev => ({ ...prev, dependentModalShow: false }))
        }}
      />
    </>
  )
}
