import React from 'react'
import { Button } from 'core/components/button'
import { classNames } from 'core/helpers/misc'

export const ActionsList = ({ actions, entity, primaryKey, forceRefresh }) => {
	return actions
		.filter(({ hideWhen = () => false }) => !hideWhen(entity))
		.map(a => (
			<Button
				customClassName={classNames('btn-sm kt-ml-10', a.style)}
				key={`${a.icon}-${a.title}`}
				title={a.title}
				icon={a.icon}
				onClick={
					typeof a.action === 'string'
						? a.action.replace(':id', `${entity[primaryKey]}`)
						: () => a.action({ entity, forceRefresh })
				}
			/>
		))
}
