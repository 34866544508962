import React from 'react'

export const ReadOnlyInput = ({ value, icon }) => (
	<>
		<input readOnly className="form-control" value={value} />

		{icon && (
			<span className="icon is-small is-left">
				<i className={icon} />
			</span>
		)}
	</>
)
