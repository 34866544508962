import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'assets/sass/datepicker.sass'
import 'assets/sass/main.scss'

import React from 'react'
import Panel from 'components/panel'
import { render } from 'react-dom'
import { Route, Router, Redirect, Switch } from 'react-router'
import { Login, AdminLogin } from 'components/login'
import { PrivateRoute } from 'core/components/route'
import { history } from 'core/helpers/history'
import * as serviceWorker from 'serviceWorker'
import { App } from 'App'
import { config } from 'config'
import { API, AdminAPI } from 'service'
import { EmployeeList } from 'components/employee'
import { ProfileList, ProfileForm } from 'components/profile'
import { LocalList } from 'components/local'
import { SecretaryList } from 'components/secretary'
import { WorkplaceList } from 'components/workplace'
import { WorkFunctionList } from 'components/work-function'
import { UserForm, UserList, UserReport } from 'components/user'
import { ClubUserForm, ClubUserList, QueryUsers } from 'components/club'
import { ElectionList } from 'components/report'
import { UserWorkList } from 'components/report'
import { ContributionForm } from 'components/integrations'
import { SchedulingForm } from 'components/scheduling'
import { LawyerForm, LawyerList } from 'components/Lawyer'
import { DoorPrizeList } from 'components/doorPrize/DoorPrizeList'
import { UserFiliation } from 'components/report/UserFiliation'
import { UserLogs } from 'components/report/UserLogs'

const rootElement = document.getElementById('root')

const Home = ({ global }) => (
  <div className="kt-portlet">
    <div className="kt-portlet__head">
      <div className="kt-portlet__head-label">
        <span className="kt-portlet__head-icon">
          <i className="kt-font-brand fas fa-home" />
        </span>
        <h3 className="kt-portlet__head-title">Olá seja bem-vindo, {global.operator.name}.</h3>
      </div>
    </div>
  </div>
)

render(
  <Router history={history}>
    <App>
      {global => (
        <Switch>
          <Route exact path="/sa/login" render={r => <AdminLogin route={r} global={global} />} />

          <PrivateRoute
            path="/sa"
            tokenKey={config.SA_TOKEN_KEY}
            render={route => (
              <Panel
                base="/sa"
                home={Home}
                service={AdminAPI}
                tokenKey={config.SA_TOKEN_KEY}
                route={route}
                global={global}
                pages={[]}
                routes={[]}
              />
            )}
          />

          <Route
            exact
            path="/login/:token?"
            render={route => {
              return <Login route={route} global={global} />
            }}
          />

          <PrivateRoute
            path="/"
            render={route => {
              return (
                <Panel
                  base=""
                  home={Home}
                  service={API}
                  tokenKey={config.TOKEN_KEY}
                  route={route}
                  global={global}
                  pages={[
                    {
                      title: 'Perfis',
                      href: '/perfil',
                      icon: 'fas fa-user-tag',
                      component: ProfileList,
                      hideWhen: operator =>
                        !['Syndicate', 'EmployeeProfiles'].some(p =>
                          operator.permissions.includes(p),
                        ),
                    },
                    {
                      title: 'Colaboradores',
                      href: ['/colaboradores', '/colaboradores/cadastro', '/colaboradores/:id'],
                      icon: 'fas fa-user-tie',
                      component: EmployeeList,
                      hideWhen: operator =>
                        !['Syndicate', 'Employees'].some(p => operator.permissions.includes(p)),
                    },
                    {
                      title: 'Municípios',
                      href: ['/empresa', '/empresa/cadastro', '/empresa/:id'],
                      icon: 'fas fa-city',
                      component: LocalList,
                      hideWhen: operator =>
                        !['Syndicate', 'Company'].some(p => operator.permissions.includes(p)),
                    },
                    {
                      title: 'Secretarias',
                      href: ['/secretaria', '/secretaria/cadastro', '/secretaria/:id'],
                      icon: 'fas fa-book',
                      component: SecretaryList,
                      hideWhen: operator =>
                        !['Syndicate', 'Secretaries'].some(p => operator.permissions.includes(p)),
                    },
                    {
                      title: 'Locais de Trabalho',
                      href: ['/trabalho-local', '/trabalho-local/cadastro', '/trabalho-local/:id'],
                      icon: 'fas fa-house-user',
                      component: WorkplaceList,
                      hideWhen: operator =>
                        !['Syndicate', 'WorkPlaces'].some(p => operator.permissions.includes(p)),
                    },
                    {
                      title: 'Funções',
                      href: ['/funcao', '/funcao/cadastro', '/funcao/:id'],
                      icon: 'fas fa-address-card',
                      component: WorkFunctionList,
                      hideWhen: operator =>
                        !['Syndicate', 'WorkFunctions'].some(p => operator.permissions.includes(p)),
                    },
                    {
                      title: 'Servidores',
                      href: '/servidores',
                      icon: 'fas fas fa-user',
                      component: UserList,
                      hideWhen: operator =>
                        !['Syndicate', 'Users'].some(p => operator.permissions.includes(p)),
                    },
                    {
                      title: 'Relatorios',
                      icon: 'fas fa-file-pdf',

                      pages: [
                        {
                          title: 'Relatório de Servidores',
                          href: '/reports/',
                          component: UserReport,
                          hideWhen: operator =>
                            !['Syndicate', 'Reports', 'UserReport'].some(p =>
                              operator.permissions.includes(p),
                            ),
                        },
                        {
                          title: 'Lista para eleições',
                          href: '/listaeleicao/',
                          component: ElectionList,
                          hideWhen: operator =>
                            !['Syndicate', 'Reports', 'ElectionReport'].some(p =>
                              operator.permissions.includes(p),
                            ),
                        },
                        {
                          title: 'Servidores por Local de trabalho',
                          href: '/userWork',
                          component: UserWorkList,
                          hideWhen: operator =>
                            !['Syndicate', 'Reports', 'UserWorkReport'].some(p =>
                              operator.permissions.includes(p),
                            ),
                        },
                        {
                          title: 'Histórico Filiação',
                          href: '/userHistory',
                          component: UserFiliation,
                          hideWhen: operator =>
                            !['Syndicate', 'Reports', 'UserFiliationReport'].some(p =>
                              operator.permissions.includes(p),
                            ),
                        },
                        {
                          title: 'Log de Alterações',
                          href: '/log',
                          component: UserLogs,
                          hideWhen: operator =>
                            !['Syndicate', 'Reports', 'UserLog'].some(p =>
                              operator.permissions.includes(p),
                            ),
                        },
                      ],
                      hideWhen: operator =>
                            !['Syndicate', 'Reports'].some(p =>
                              operator.permissions.includes(p),
                            ),
                    },
                    {
                      title: 'Integrações',
                      icon: 'fas fa-file-import',

                      pages: [
                        {
                          title: 'Contribuição',
                          href: '/contribuicao/',
                          component: ContributionForm,
                          hideWhen: operator =>
                            !['Syndicate', 'Integrations', 'Contribution'].some(p =>
                              operator.permissions.includes(p),
                            ),
                        },
                      ],
                      hideWhen: operator =>
                            !['Syndicate', 'Integrations'].some(p =>
                              operator.permissions.includes(p),
                            ),
                    },
                    {
                      title: 'Clube',
                      href: '/clube',
                      icon: 'fas fas fa-user',
                      component: ClubUserList,
                      hideWhen: operator =>
                        !['Syndicate', 'Club'].some(p => operator.permissions.includes(p)),
                    },
                    {
                      title: 'Consulta Associados',
                      href: '/associados',
                      icon: 'fas fas fa-user',
                      component: QueryUsers,
                      hideWhen: operator =>
                        !['Syndicate', 'QueryUsers'].some(p => operator.permissions.includes(p)),
                    },
                    {
                      title: 'Sorteios',
                      href: ['/sorteio', '/sorteio/cadastro', '/sorteio/:id'],
                      icon: 'fas fa-book',
                      component: DoorPrizeList,
                      hideWhen: operator =>
                        !['Syndicate', 'DoorPrize'].some(p => operator.permissions.includes(p)),
                    },
                    {
                      title: 'Juridico',
                      icon: 'fas fa-gavel',
                      hideWhen: operator =>
                        !['Juridical', 'Jurídico'].some(p => operator.permissions.includes(p)),
                      pages: [
                        {
                          title: 'Advogado',
                          href: '/advogado/',
                          component: LawyerList,
                          hideWhen: operator =>
                            !['Juridical', 'Lawyer'].some(p => operator.permissions.includes(p)),
                        },
                        {
                          title: 'Agendamento',
                          href: ['/agendamento', '/agendamento/cadastro', '/agendamento/:id'],
                          icon: 'fas fas fa-user',
                          component: SchedulingForm,
                          hideWhen: operator =>
                            !['Juridical', 'Schedule'].some(p => operator.permissions.includes(p)),
                        },
                      ],
                    },
                  ]}
                  routes={[
                    {
                      path: ['/perfil/cadastro', '/perfil/:id'],
                      component: ProfileForm,
                    },
                    {
                      path: ['/servidores/cadastro', '/servidores/:id'],
                      component: UserForm,
                    },
                    {
                      path: ['/clube/cadastro', '/clube/:id'],
                      component: ClubUserForm,
                    },
                    {
                      path: ['/advogado/cadastro', '/advogado/:id'],
                      component: LawyerForm,
                    },
                  ]}
                />
              )
            }}
          />
          <Route render={() => <Redirect to="login" />} />
        </Switch>
      )}
    </App>
  </Router>,
  rootElement,
)

serviceWorker.register()
