import React, { useState, useRef } from 'react'
import { List } from 'core/components/list'
import { Link } from 'core/components/route'
import { onlyNumbers } from 'core/helpers/format'
import { useRefresh } from 'core/hooks/refresh'
import { Field } from 'core/components/form/field'
import { TextInput } from 'core/components/form/text-input'
import { useFilters } from 'core/hooks/filter'
import { CpfMask } from 'core/constants'
import { Button } from 'core/components/button'
import { AsyncSelect } from 'core/components/form/async-select'
import { applyMask } from 'core/helpers/mask'
import { CellPhoneMask } from 'core/constants'
import { Select } from 'core/components/form/select'
import { convertQuery } from 'service/helpers'
import { iframeDownload, forceDownload } from 'core/helpers/misc'

const getRetireFormat = ({ retiredAt }) => {
  return retiredAt === null ? 'Não' : 'Sim'
}

const getWhatsAppFormat = ({ cellPhone }) => {
  if (!cellPhone) {
    return ''
  }

  return (
    <>
      <span>{applyMask(CellPhoneMask, cellPhone)}</span>
      <Link
        className="btn btn-sm"
        target="_blank"
        to={`http://api.whatsapp.com/send?1=pt_BR&phone=55${cellPhone}`}
      >
        <i style={{ color: 'green' }} className="fab fa-whatsapp" />
      </Link>
    </>
  )
}

const columns = [
  { path: 'id', title: '#', textAlign: 'center', sort: 'index' },
  { path: 'cpf', title: 'CPF', format: 'cpfcnpj' },
  { path: 'name', title: 'Nome' },
  { path: 'localDescription', title: 'Município' },
  { path: 'retiredAt', title: 'Aposentado', format: getRetireFormat },
  { path: 'cellPhone', title: 'Celular', format: getWhatsAppFormat },
]

const userTypes = [
  { id: 0, name: 'Todos' },
  { id: 1, name: 'Filiado' },
  { id: 2, name: 'Desfiliado' },
]
const userDisabled = [
  { id: 0, name: 'Todos' },
  { id: 1, name: 'Ativos' },
  { id: 2, name: 'Desativados' },
]
const userDefaulter = [
  { id: 0, name: 'Todos' },
  { id: 1, name: 'Em dia' },
  { id: 2, name: 'Inadimplente' },
]

const fields = [...columns.map(c => c.path), 'disabledAt', 'whatsApp']

export const disableUser = ({ modal, data: { id, name, disabledAt }, callback, service }) =>
  modal.confirm(
    `Deseja ${!disabledAt ? 'bloquear' : 'desbloquear'} ${name}?`,
    confirmed =>
      confirmed &&
      service
        .remove('user', id)
        .then(callback)
        .catch(err => modal.alert(err.message)),
  )

export const UserList = ({ global, route, service, basename }) => {
  const { modal } = global
  const query = new URLSearchParams(route.location.search)

  const [dbFilters, setDbFilters] = useState({
    name: '',
    cpf: '',
    userLocalId: query.get('local') || '',
    secretaryId: query.get('secretary') || '',
    workPlaceId: query.get('workplace') || '',
    workFunctionId: query.get('function') || '',
    type: 1,
    disabled: 1,
    defaulter: 0,
  })
  const [downloading, setDownloading] = useState(false)
  const queryRef = useRef({})
  function handleTagDownload() {
    setDownloading(true)
    service
      .post('user.tag', { ...convertQuery(queryRef.current), type: 'pdf' }, undefined, resp =>
        resp.blob(),
      )
      .then(blob => iframeDownload(blob, 'Etiqueta.pdf'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }
  function handleXlsxDownload() {
    setDownloading(true)
    service
      .post('user.export', { ...convertQuery(queryRef.current), type: 'xlsx' }, undefined, resp =>
        resp.blob(),
      )
      .then(blob => forceDownload(blob, 'relatorio.xlsx'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }

  const { setValues, filters } = useFilters(dbFilters, query => [
    {
      items: [
        { name: 'name', value: query.name, comparer: 'Like' },
        { name: 'cpf', value: onlyNumbers(query.cpf), comparer: 'Like' },
        { name: 'userLocalId', value: query.userLocalId },
        { name: 'secretaryId', value: query.secretaryId },
        { name: 'workPlaceId', value: query.workPlaceId },
        { name: 'workFunctionId', value: query.workFunctionId },
        query.type === 1
          ? {
              name: 'User.disaffiliated',
              value: false,
              comparer: 'Equals',
            }
          : {},
        query.type === 2
          ? {
              name: 'User.disaffiliated',
              value: true,
              comparer: 'Equals',
            }
          : {},
        query.disabled === 1
          ? {
              name: 'User.disabledAt',
              value: null,
              comparer: 'Equals',
            }
          : {},
        query.disabled === 2
          ? {
              name: 'User.disabledAt',
              value: null,
              comparer: 'NotEquals',
            }
          : {},
        query.defaulter === 1
          ? {
              name: 'User.defaulter',
              value: false,
              comparer: 'Equals',
            }
          : {},
        query.defaulter === 2
          ? {
              name: 'User.defaulter',
              value: true,
              comparer: 'Equals',
            }
          : {},
      ],
    },
  ])

  const fetching = useState(false)
  const refresh = useRefresh()

  return (
    <>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fas fas fa-user" />
            </span>
            <h3 className="kt-portlet__head-title">Servidores</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <div className="dropdown dropdown-inline">
                <Link
                  className="btn btn-success btn-icon-sm"
                  to={`${basename}/servidores/cadastro`}
                >
                  <i className="fas fa-plus" /> Novo Servidor
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-pb-0 position-relative">
          <div className="row">
            <div className="col-lg-3">
              <Field label="CPF">
                <TextInput
                  ignoreBlur
                  type="search"
                  mask={CpfMask}
                  value={dbFilters.cpf}
                  onChange={cpf => setDbFilters(prev => ({ ...prev, cpf }))}
                />
              </Field>
            </div>

            <div className="col-lg-3">
              <Field label="Nome">
                <TextInput
                  ignoreBlur
                  type="search"
                  value={dbFilters.name}
                  onChange={name => setDbFilters(prev => ({ ...prev, name }))}
                />
              </Field>
            </div>

            <div className="col-lg-3">
              <Field label="Tipo de Usuário">
                <Select
                  items={userTypes}
                  selected={dbFilters.type}
                  getId={({ id }) => id}
                  getDisplay={({ name }) => name}
                  onChange={({ id }) =>
                    setDbFilters(prev => ({
                      ...prev,
                      type: id,
                    }))
                  }
                />
              </Field>
            </div>
            <div className="col-lg-3">
              <Field label="Ativo">
                <Select
                  items={userDisabled}
                  selected={dbFilters.disabled}
                  getId={({ id }) => id}
                  getDisplay={({ name }) => name}
                  onChange={({ id }) =>
                    setDbFilters(prev => ({
                      ...prev,
                      disabled: id,
                    }))
                  }
                />
              </Field>
            </div>
          </div>

          <div className="row">
            <div className="col-lg">
              <Field label="Município">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ description }) => description}
                  getItems={value =>
                    service
                      .getList('local', {
                        sort: [['description', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'description',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.userLocalId}
                  onChange={local =>
                    setDbFilters(prev => ({
                      ...prev,
                      userLocalId: local ? local.id : '',
                    }))
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Secretaria">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ description }) => description}
                  getItems={value =>
                    service
                      .getList('secretary', {
                        sort: [['description', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'description',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.secretaryId}
                  onChange={secretary =>
                    setDbFilters(prev => ({
                      ...prev,
                      secretaryId: secretary ? secretary.id : '',
                    }))
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Locais de Trabalho">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ description }) => description}
                  getItems={value =>
                    service
                      .getList('workPlace', {
                        sort: [['description', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'description',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.workPlaceId}
                  onChange={workPlace =>
                    setDbFilters(prev => ({
                      ...prev,
                      workPlaceId: workPlace ? workPlace.id : '',
                    }))
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Funções">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ description }) => description}
                  getItems={value =>
                    service
                      .getList('workFunction', {
                        sort: [['description', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'description',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.workFunctionId}
                  onChange={workFunction =>
                    setDbFilters(prev => ({
                      ...prev,
                      workFunctionId: workFunction ? workFunction.id : '',
                    }))
                  }
                />
              </Field>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <Field label="Inadimplentes">
                <Select
                  items={userDefaulter}
                  selected={dbFilters.defaulter}
                  getId={({ id }) => id}
                  getDisplay={({ name }) => name}
                  onChange={({ id }) =>
                    setDbFilters(prev => ({
                      ...prev,
                      defaulter: id,
                    }))
                  }
                />
              </Field>
            </div>
          </div>

          <div className="row">
            <div className="col-lg kt-align-right">
              <Field>
                <Button
                  customClassName="btn-dark btn-icon-sm margin-right-10"
                  icon="fas fa-file-excel"
                  onClick={handleXlsxDownload}
                  disabled={downloading}
                  title="Exportar XLSX"
                />
                <Button
                  customClassName="btn-dark btn-icon-sm margin-right-10"
                  icon="fas fa-file-pdf"
                  onClick={handleTagDownload}
                  disabled={downloading}
                  title="Gerar Etiqueta"
                />
                <Button
                  customClassName="btn-info btn-icon-sm"
                  icon="fas fa-search"
                  onClick={() => {
                    setValues(prev => ({
                      ...prev,
                      ...dbFilters,
                    }))
                  }}
                  title="Consultar"
                />
              </Field>
            </div>
          </div>

          {fetching[0] && (
            <div className="blockui-overlay">
              <div className="blockui" />
            </div>
          )}
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-separator kt-separator--space-sm" />

          <List
            primaryKey="id"
            modal={modal}
            queryRef={queryRef}
            route={route}
            refresh={refresh}
            fetching={fetching}
            filters={filters}
            initialQuery={{ fields }}
            getItems={(query, signal) => service.getList('user', query, signal)}
            actions={[
              {
                icon: 'fas fa-edit',
                title: 'Editar',
                action: `${basename}/servidores/:id`,
              },
              {
                icon: 'fas fa-lock',
                title: 'Bloquear',
                hideWhen: ent => !!ent.disabledAt,
                action: ({ entity, forceRefresh }) =>
                  disableUser({
                    callback: forceRefresh,
                    data: entity,
                    modal,
                    service,
                  }),
              },
              {
                icon: 'fas fa-unlock',
                title: 'Desbloquear',
                hideWhen: ent => !ent.disabledAt,
                action: ({ entity, forceRefresh }) =>
                  disableUser({
                    modal,
                    callback: forceRefresh,
                    data: entity,
                    service,
                  }),
              },
            ]}
            columns={columns}
          />
        </div>
      </div>
    </>
  )
}
