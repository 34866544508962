import { AsyncSelect } from 'core/components/form/async-select'
import endOfDay from 'date-fns/endOfDay'
import React, { useState, useRef } from 'react'
import { List } from 'core/components/list'
import { useRefresh } from 'core/hooks/refresh'
import { Field } from 'core/components/form/field'
import { Select } from 'core/components/form/select'
import { useFilters } from 'core/hooks/filter'
import startOfDay from 'date-fns/startOfDay'
import { Button } from 'core/components/button'

import { DateInput } from 'core/components/form/date-input'
import { TextInput } from 'core/components/form/text-input'
import { convertQuery } from 'service/helpers'
import { forceDownload } from 'core/helpers/misc'

import { BirthdateMask } from 'core/constants'
const getAfilliateFormat = ({ disaffiliated }) => (disaffiliated ? 'Não' : 'Sim')
const columns = [
  { path: 'name', title: 'Nome' },
  { path: 'localDescription', title: 'Município' },
  {
    path: 'disaffiliated',
    title: 'Filiado',
    format: getAfilliateFormat,
  },
  { path: 'affiliateDate', title: 'Data Filiação', format: 'date' },
  { path: 'disaffiliatedAt', title: 'Data Desfiliação', format: 'date' },
]

export const UserFiliation = ({ global, route, service, basename }) => {
  const { modal } = global

  const [downloading, setDownloading] = useState(false)
  const [dbFilters, setDbFilters] = useState({
    name: '',
    disaffiliated: null,
    startDate: null,
    endDate: null,
    startDisaffiliate: null,
    endDisaffiliate: null,
    type: null,
    userLocalId: '',
  })
  const queryRef = useRef({})

  const { setValues, filters } = useFilters(dbFilters, query => [
    {
      items: [
        { name: 'name', value: query.name, comparer: 'Like' },

        query.startDate
          ? { name: 'affiliateDate', value: query.startDate, comparer: 'GreaterThanOrEqual' }
          : {},
        query.endDate
          ? { name: 'affiliateDate', value: query.endDate, comparer: 'LessThanOrEqual' }
          : {},
        query.startDisaffiliate
          ? {
              name: 'disaffiliatedAt',
              value: query.startDisaffiliate,
              comparer: 'GreaterThanOrEqual',
            }
          : {},
        query.endDisaffiliate
          ? { name: 'disaffiliatedAt', value: query.endDisaffiliate, comparer: 'LessThanOrEqual' }
          : {},
        query.type != null ? { name: 'disaffiliated', value: query.type, comparer: 'Equals' } : {},
        { name: 'userLocalId', value: query.userLocalId },
      ],
    },
  ])
  function handleXlsxDownload() {
    setDownloading(true)
    service
      .post('user.export', { ...convertQuery(queryRef.current), type: 'xlsx' }, undefined, resp =>
        resp.blob(),
      )
      .then(blob => forceDownload(blob, 'relatorio.xlsx'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }

  function handlePdfDownload() {
    setDownloading(true)
    service
      .post('user.export', { ...convertQuery(queryRef.current), type: 'pdf' }, undefined, resp =>
        resp.blob(),
      )
      .then(blob => forceDownload(blob, 'relatorio.pdf'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }

  const fetching = useState(false)
  const refresh = useRefresh()
  const userTypes = [
    { id: null, name: 'Todos' },
    { id: false, name: 'Filiado' },
    { id: true, name: 'Desfiliado' },
  ]
  return (
    <>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fas fa-user-friends" />
            </span>
            <h3 className="kt-portlet__head-title">Relatorio de Filiação/Desfiliação</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-pb-0 position-relative">
          <div className="row">
            <div className="col-lg-3">
              <Field label="Nome">
                <TextInput
                  ignoreBlur
                  type="search"
                  value={dbFilters.name}
                  onChange={name => setDbFilters(prev => ({ ...prev, name }))}
                />
              </Field>
            </div>
            <div className="col-lg-3">
              <Field label="Filiados/Desfiliados">
                <Select
                  items={userTypes}
                  selected={dbFilters.type}
                  getId={({ id }) => id}
                  getDisplay={({ name }) => name}
                  onChange={({ id }) =>
                    setDbFilters(prev => ({
                      ...prev,
                      type: id,
                    }))
                  }
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Município">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ description }) => description}
                  getItems={value =>
                    service
                      .getList('local', {
                        sort: [['description', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'description',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.userLocalId}
                  onChange={local =>
                    setDbFilters(prev => ({
                      ...prev,
                      userLocalId: local ? local.id : '',
                    }))
                  }
                />
              </Field>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <Field label="Data Inicial Filiação">
                <DateInput
                  isClearable
                  customClassName="form-control-xl"
                  mask={BirthdateMask}
                  onChange={date =>
                    setDbFilters(prev => ({
                      ...prev,
                      startDate: date ? startOfDay(date) : null,
                    }))
                  }
                  value={dbFilters.startDate}
                />
              </Field>
            </div>
            <div className="col-lg-3">
              <Field label="Data Final Filiação">
                <DateInput
                  isClearable
                  customClassName="form-control-xl"
                  mask={BirthdateMask}
                  onChange={date =>
                    setDbFilters(prev => ({
                      ...prev,
                      endDate: date ? endOfDay(date) : null,
                    }))
                  }
                  value={dbFilters.endDate}
                />
              </Field>
            </div>
            <div className="col-lg-3">
              <Field label="Data Inicial Desfiliação">
                <DateInput
                  isClearable
                  customClassName="form-control-xl"
                  mask={BirthdateMask}
                  onChange={date =>
                    setDbFilters(prev => ({
                      ...prev,
                      startDisaffiliate: date ? startOfDay(date) : null,
                    }))
                  }
                  value={dbFilters.startDisaffiliate}
                />
              </Field>
            </div>
            <div className="col-lg-3">
              <Field label="Data Final Desfiliação">
                <DateInput
                  isClearable
                  customClassName="form-control-xl"
                  mask={BirthdateMask}
                  onChange={date =>
                    setDbFilters(prev => ({
                      ...prev,
                      endDisaffiliate: date ? endOfDay(date) : null,
                    }))
                  }
                  value={dbFilters.endDisaffiliate}
                />
              </Field>
            </div>
          </div>

          <div className="row">
            <div className="col-lg kt-align-right">
              <Field>
                <Button
                  customClassName="btn-dark btn-icon-sm margin-right-10"
                  icon="fas fa-file-excel"
                  onClick={handleXlsxDownload}
                  disabled={downloading}
                  title="Exportar XLSX"
                />
                <Button
                  customClassName="btn-dark btn-icon-sm margin-right-10"
                  icon="fas fa-file-pdf"
                  onClick={handlePdfDownload}
                  disabled={downloading}
                  title="Exportar PDF"
                />
                <Button
                  customClassName="btn-info btn-icon-sm"
                  icon="fas fa-search"
                  onClick={() => {
                    setValues(prev => ({
                      ...prev,
                      ...dbFilters,
                    }))
                  }}
                  title="Consultar"
                />
              </Field>
            </div>
          </div>

          {fetching[0] && (
            <div className="blockui-overlay">
              <div className="blockui" />
            </div>
          )}
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-separator kt-separator--space-sm" />

          <List
            queryRef={queryRef}
            primaryKey="id"
            modal={modal}
            route={route}
            refresh={refresh}
            fetching={fetching}
            filters={filters}
            initialQuery={{
              fields: columns.map(c => c.path),
              perPage: 20,
              sort: [['name', 'ASC']],
            }}
            getItems={(query, signal) => service.post('user.report', convertQuery(query), signal)}
            columns={columns}
          />
        </div>
      </div>
    </>
  )
}
