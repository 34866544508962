import React, { useState, useRef } from 'react'
import { List } from 'core/components/list'
import { Link } from 'core/components/route'
import { useRefresh } from 'core/hooks/refresh'
import { Field } from 'core/components/form/field'
import { TextInput } from 'core/components/form/text-input'
import { useFilters } from 'core/hooks/filter'
import { WorkFunctionForm } from './WorkFunctionForm'
import { parseNumber } from 'core/helpers/parse'
import { Button } from 'core/components/button'
import { convertQuery } from 'service/helpers'
import { forceDownload } from 'core/helpers/misc'

const columns = [
	{ path: 'id', title: '#', textAlign: 'center', sort: 'index' },
	{ path: 'description', title: 'Descrição' },
	{ path: 'localDescription', title: 'Município' },
]

const fields = [...columns.map(c => c.path), 'disabledAt']
export const disableWorkFunction = ({
	modal,
	data: { id, description, disabledAt },
	callback,
	service,
}) =>
	modal.confirm(
		`Deseja ${!disabledAt ? 'desabilitar' : 'habilitar'} ${description}?`,
		confirmed =>
			confirmed &&
			service
				.remove('workFunction', id)
				.then(callback)
				.catch(err => modal.alert(err.message)),
	)

export const WorkFunctionList = ({ global, route, service, basename }) => {
	const { modal } = global
	const queryRef = useRef({})
	const [downloading, setDownloading] = useState(false)
	const [dbFilters, setDbFilters] = useState({ description: '', local: '' })
	function handleXlsxDownload() {
		setDownloading(true)
		service
			.post(
				'workfunction.export',
				{ ...convertQuery(queryRef.current), type: 'xlsx' },
				undefined,
				resp => resp.blob(),
			)
			.then(blob => forceDownload(blob, 'Funções.xlsx'))
			.catch(err => modal.alert(err.message))
			.finally(() => setDownloading(false))
	}
	const { setValues, filters } = useFilters({ description: '', local: '' }, query => [
		{
			items:
				[
					{ name: 'WorkFunction.description', value: query.description, comparer: 'Like' },
					{ name: 'localDescription', value: query.local, comparer: 'Like' }
				],

		},
	])

	const fetching = useState(false)
	const refresh = useRefresh()
	const id = parseNumber(route.match.params.id)
	const showForm = route.location.pathname.includes('cadastro') || id > 0

	return (
		<>
			<div className="kt-portlet kt-portlet--mobile">
				<div className="kt-portlet__head kt-portlet__head--lg">
					<div className="kt-portlet__head-label">
						<span className="kt-portlet__head-icon">
							<i className="kt-font-brand fas fa-address-card" />
						</span>
						<h3 className="kt-portlet__head-title">Funções</h3>
					</div>
					<div className="kt-portlet__head-toolbar">
						<div className="kt-portlet__head-wrapper">
							<div className="dropdown dropdown-inline">
								<Link
									className="btn btn-success btn-icon-sm"
									to={`${basename}/funcao/cadastro`}
								>
									<i className="fas fa-plus" /> Nova Função
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="kt-portlet__body kt-pb-0 position-relative">
					<div className="row">
						<div className="col-lg-4">
							<Field label="Descrição">
								<TextInput
									ignoreBlur
									type="search"
									value={dbFilters.description}
									onChange={description =>
										setDbFilters(prev => ({ ...prev, description }))
									}
								/>
							</Field>
						</div>
						<div className="col-lg-4">
							<Field label="Município">
								<TextInput
									ignoreBlur
									type="search"
									value={dbFilters.local}
									onChange={local =>
										setDbFilters(prev => ({ ...prev, local }))
									}
								/>
							</Field>
						</div>
					</div>
					<div className="row">
						<div className="col-lg kt-align-right">
							<Field>
								<Button
									customClassName="btn-dark btn-icon-sm margin-right-10"
									icon="fas fa-file-excel"
									onClick={handleXlsxDownload}
									disabled={downloading}
									title="Exportar XLSX"
								/>
								<Button
									customClassName="btn-info btn-icon-sm"
									icon="fas fa-search"
									onClick={() => {
										setValues(prev => ({
											...prev,
											...dbFilters,
										}))
									}}
									title="Consultar"
								/>
							</Field>
						</div>
					</div>

					{fetching[0] && (
						<div className="blockui-overlay">
							<div className="blockui" />
						</div>
					)}
				</div>
				<div className="kt-portlet__body kt-portlet__body--fit">
					<div className="kt-separator kt-separator--space-sm" />

					<List
						queryRef={queryRef}
						primaryKey="id"
						modal={modal}
						route={route}
						refresh={refresh}
						fetching={fetching}
						filters={filters}
						initialQuery={{ fields }}
						getItems={(query, signal) => service.getList('workfunction', query, signal)}
						actions={[
							{
								icon: 'fas fa-user',
								title: 'Servidores',
								action: `${basename}/servidores?function=:id`,
							},
							{
								icon: 'fas fa-edit',
								title: 'Editar',
								action: `${basename}/funcao/:id`,
							},
							{
								icon: 'fas fa-lock',
								title: 'Desabilitar',
								hideWhen: ent => !!ent.disabledAt,
								action: ({ entity, forceRefresh }) =>
									disableWorkFunction({
										callback: forceRefresh,
										data: entity,
										modal,
										service,
									}),
							},
							{
								icon: 'fas fa-unlock',
								title: 'Habilitar',
								hideWhen: ent => !ent.disabledAt,
								action: ({ entity, forceRefresh }) =>
									disableWorkFunction({
										modal,
										callback: forceRefresh,
										data: entity,
										service,
									}),
							},
						]}
						columns={columns}
					/>
				</div>
			</div>

			<WorkFunctionForm
				service={service}
				show={showForm}
				route={route}
				id={id}
				refresh={async () => {
					await global.refresh(true)
					refresh.force()
				}}
			/>
		</>
	)
}
