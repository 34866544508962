import { AsyncSelect } from 'core/components/form/async-select'
import endOfDay from 'date-fns/endOfDay'
import React, { useState, useRef } from 'react'
import { List } from 'core/components/list'
import { useRefresh } from 'core/hooks/refresh'
import { Field } from 'core/components/form/field'
import { useFilters } from 'core/hooks/filter'
import startOfDay from 'date-fns/startOfDay'
import { Button } from 'core/components/button'

import { DateInput } from 'core/components/form/date-input'
import { convertQuery } from 'service/helpers'
import { forceDownload } from 'core/helpers/misc'

import { BirthdateMask } from 'core/constants'
const getAfilliateFormatOldValue = ({ oldValue }) =>
  oldValue === 'false'
    ? 'Não'
    : oldValue === 'true'
    ? 'Sim'
    : oldValue === 'undefined' || oldValue === 'null'
    ? 'Vazio'
    : oldValue
const getAfilliateFormatNewValue = ({ newValue }) =>
  newValue === 'false'
    ? 'Não'
    : newValue === 'true'
    ? 'Sim'
    : newValue === 'undefined' || newValue === 'null'
    ? 'Vazio'
    : newValue
const columns = [
  { path: 'id', title: '#', textAlign: 'center', sort: 'createdAt' },
  { path: 'userName', title: 'Servidor' },
  { path: 'employeeName', title: 'Colaborador' },
  { path: 'field', title: 'Campo Alterado' },
  { path: 'oldValue', title: 'Valor Anterior', format: getAfilliateFormatOldValue },
  { path: 'newValue', title: 'Valor novo', format: getAfilliateFormatNewValue },
  { path: 'createdAt', title: 'Data de Alteração', format: 'date' },
]

export const UserLogs = ({ global, route, service, basename }) => {
  const { modal } = global

  const [downloading, setDownloading] = useState(false)
  const [dbFilters, setDbFilters] = useState({
    userId: '',
    employeeId: '',
    startDate: null,
    endDate: null,
  })
  const queryRef = useRef({})

  const { setValues, filters } = useFilters(dbFilters, query => [
    {
      items: [
        { name: 'UserLogs.userId', value: query.userId },
        { name: 'UserLogs.employeeId', value: query.employeeId },

        query.startDate
          ? { name: 'UserLogs.createdAt', value: query.startDate, comparer: 'GreaterThanOrEqual' }
          : {},
        query.endDate
          ? { name: 'UserLogs.createdAt', value: query.endDate, comparer: 'LessThanOrEqual' }
          : {},
      ],
    },
  ])
  // function handleXlsxDownload() {
  //   setDownloading(true)
  //   service
  //     .post('user.logs', { ...convertQuery(queryRef.current), type: 'xlsx' }, undefined, resp =>
  //       resp.blob(),
  //     )
  //     .then(blob => forceDownload(blob, 'relatorio.xlsx'))
  //     .catch(err => modal.alert(err.message))
  //     .finally(() => setDownloading(false))
  // }

  function handlePdfDownload() {
    setDownloading(true)
    service
      .post('user.logs', { ...convertQuery(queryRef.current), type: 'pdf' }, undefined, resp =>
        resp.blob(),
      )
      .then(blob => forceDownload(blob, 'relatorio.pdf'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }

  const fetching = useState(false)
  const refresh = useRefresh()

  return (
    <>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fas fa-user-friends" />
            </span>
            <h3 className="kt-portlet__head-title">Log de Alterações</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-pb-0 position-relative">
          <div className="row">
            <div className="col-lg-3">
              <Field label="Servidor">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ name }) => name}
                  getItems={value =>
                    service
                      .getList('user', {
                        sort: [['name', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'name',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.userId}
                  onChange={user =>
                    setDbFilters(prev => ({
                      ...prev,
                      userId: user ? user.id : '',
                    }))
                  }
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Usuario">
                <AsyncSelect
                  isClearable
                  getId={({ id }) => id}
                  getDisplay={({ name }) => name}
                  getItems={value =>
                    service
                      .getList('employee', {
                        sort: [['name', 'ASC']],
                        filters: [
                          {
                            items: [
                              {
                                name: 'name',
                                comparer: 'Like',
                                value,
                              },
                            ],
                          },
                        ],
                        usePager: false,
                      })
                      .then(({ items }) => items)
                  }
                  selected={dbFilters.employeeId}
                  onChange={employee =>
                    setDbFilters(prev => ({
                      ...prev,
                      employeeId: employee ? employee.id : '',
                    }))
                  }
                />
              </Field>
            </div>
            <div className="col-lg-3">
              <Field label="Data Inicial">
                <DateInput
                  isClearable
                  customClassName="form-control-xl"
                  mask={BirthdateMask}
                  onChange={date =>
                    setDbFilters(prev => ({
                      ...prev,
                      startDate: date ? startOfDay(date) : null,
                    }))
                  }
                  value={dbFilters.startDate}
                />
              </Field>
            </div>
            <div className="col-lg-3">
              <Field label="Data Final">
                <DateInput
                  isClearable
                  customClassName="form-control-xl"
                  mask={BirthdateMask}
                  onChange={date =>
                    setDbFilters(prev => ({
                      ...prev,
                      endDate: date ? endOfDay(date) : null,
                    }))
                  }
                  value={dbFilters.endDate}
                />
              </Field>
            </div>
          </div>

          <div className="row">
            <div className="col-lg kt-align-right">
              <Field>
                {/* <Button
                  customClassName="btn-dark btn-icon-sm margin-right-10"
                  icon="fas fa-file-excel"
                  onClick={handleXlsxDownload}
                  disabled={downloading}
                  title="Exportar XLSX"
                /> */}
                <Button
                  customClassName="btn-dark btn-icon-sm margin-right-10"
                  icon="fas fa-file-pdf"
                  onClick={handlePdfDownload}
                  disabled={downloading}
                  title="Exportar PDF"
                />
                <Button
                  customClassName="btn-info btn-icon-sm"
                  icon="fas fa-search"
                  onClick={() => {
                    setValues(prev => ({
                      ...prev,
                      ...dbFilters,
                    }))
                  }}
                  title="Consultar"
                />
              </Field>
            </div>
          </div>

          {fetching[0] && (
            <div className="blockui-overlay">
              <div className="blockui" />
            </div>
          )}
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-separator kt-separator--space-sm" />

          <List
            queryRef={queryRef}
            primaryKey="id"
            modal={modal}
            route={route}
            refresh={refresh}
            fetching={fetching}
            filters={filters}
            initialQuery={{
              fields: columns.map(c => c.path),
              perPage: 20,
              sort: [['createdAt', 'DESC']],
            }}
            getItems={(query, signal) => service.post('log.list', convertQuery(query), signal)}
            columns={columns}
          />
        </div>
      </div>
    </>
  )
}
