import React, { useState, useEffect } from 'react'
import { Field } from 'core/components/form/field'
import { classNames } from 'core/helpers/misc'
import { toLocaleDateTime } from 'core/helpers/date'
import { useDebounce } from 'core/hooks/debounce'
import { Button } from 'core/components/button'
import { LoadingCard } from 'core/components/loading-card'
import { Pagination } from 'core/components/list/Pagination'
import { AsyncSelect } from 'core/components/form/async-select'
import { DateInput } from 'core/components/form/date-input'
import { iframeDownload } from 'core/helpers/misc'
import { useRefresh } from 'core/hooks/refresh'

export const ContributionForm = ({ global, service, forceRefresh }) => {
  const refresh = useRefresh()
  const [unique, setUnique] = useState(null)
  const [downloading, setDownloading] = useState(false)
  const [file, setCardFile] = useState()
  const handleUploadFile = e => setCardFile(e.target.files[0])
  const [items, setItems] = useState([])
  const [filters, setDbFilters] = useState({
    userLocalId: 0,
    date: null,
  })
  const dbFilters = useDebounce(filters)
  const [fetching, setFetching] = useState(false)
  const [page, setPage] = useState(1)
  const [footer, setFooter] = useState({
    pages: 1,
    records: 0,
  })
  function validadeUniqueKey(entity) {
    setFetching(true)
    service
      .post('contribution.uniqueKey', { ...entity })
      .then(({ unique }) => setUnique(unique))
      .catch(err => global.modal.alert(err.message))
      .finally(() => setFetching(false))
  }
  function handleImportDownload(entity) {
    setDownloading(true)
    service
      .post('contributionreport.import', { ...entity }, undefined, resp => resp.blob())
      .then(blob => iframeDownload(blob, 'Importação.pdf'))
      .catch(err => global.modal.alert(err.message))
      .finally(() => setDownloading(false))
  }

  function getReport(page) {
    setFetching(true)
    service
      .post('contribution.list', { ...dbFilters, page })
      .then(({ items, pager }) => {
        setItems(items)
        if (pager) setFooter(pager)
      })
      .catch(err => global.modal.alert(err.message))
      .finally(() => setFetching(false))
  }
  useEffect(() => {
    getReport(page)
    // eslint-disable-next-line
  }, [page])
  useEffect(() => {
    if (dbFilters.userLocalId && dbFilters.date) validadeUniqueKey(dbFilters)
    // eslint-disable-next-line
  }, [dbFilters])

  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet__head kt-portlet__head--lg">
        <div className="kt-portlet__head-label">
          <span className="kt-portlet__head-icon">
            <i className="kt-font-brand fas fa-file-export" />
          </span>
          <h3 className="kt-portlet__head-title">Importação Contribuição</h3>
        </div>
      </div>
      <div className="kt-portlet__body kt-pb-0 position-relative">
        <div className="row">
          <div className="col-lg">
            <Field label="Município">
              <AsyncSelect
                isClearable
                getId={({ id }) => id}
                getDisplay={({ description }) => description}
                getItems={value =>
                  service
                    .getList('local', {
                      sort: [['description', 'ASC']],
                      filters: [
                        {
                          items: [
                            {
                              name: 'description',
                              comparer: 'Like',
                              value,
                            },
                          ],
                        },
                      ],
                      usePager: false,
                    })
                    .then(({ items }) => items)
                }
                selected={dbFilters.userLocalId}
                onChange={local =>
                  setDbFilters(prev => ({
                    ...prev,
                    userLocalId: local ? local.id : '',
                  }))
                }
              />
            </Field>
          </div>
          <div className="col-lg">
            <Field label="Data">
              <DateInput
                customClassName="form-control-xl"
                dateFormat="MM/yyyy"
                onChange={date =>
                  setDbFilters(prev => ({
                    ...prev,
                    date,
                  }))
                }
                value={dbFilters.date || null}
              />
            </Field>
          </div>
        </div>
        <div className="row">
          <div className="col-lg">
            <Field label="Selecionar Arquivos">
              <div className="form-control-xl">
                <button type="button" className="close" aria-label="close" data-dismiss="modal" />
              </div>
              <div className="row">
                <div className="col-lg">
                  <input type="file" onChange={handleUploadFile} accept=".xlsx" id="fileid" />
                </div>
              </div>
            </Field>
          </div>
        </div>
        <div className="row">
          <div></div>
          <div className="col-lg kt-align-right">
            <Field>
              <Button
                icon="fas fa-file-alt"
                customClassName="btn-info btn-icon-sm"
                title="Importar CSV"
                onClick={() => {
                  // download pdf
                  if (dbFilters.date && dbFilters.userLocalId) {
                    if (unique) {
                      setFetching(true)
                      service
                        .uploadFile('contribution.import', file, dbFilters)
                        .then(value =>
                          setDbFilters(prev => ({
                            ...prev,
                            id: value.id,
                          })),
                        )
                        .catch(err => {
                          global.modal.alert(err.message)
                        })
                        .then(
                          setDbFilters(prev => ({
                            ...prev,
                            userLocalId: null,
                            date: null,
                          })),
                          getReport(page),
                        )
                        .finally(() => {
                          setFetching(false)
                          getReport(page)
                        })
                    } else {
                      global.modal.confirm(
                        'Já possui uma importação essa data e municipio, Deseja reprocessar ? ',
                        confirmed => {
                          if (confirmed) {
                            setFetching(true)
                            service
                              .uploadFile('contribution.import', file, dbFilters, true)
                              .then(refresh)
                              .catch(err => {
                                global.modal.alert(err.message)
                              })
                              .then(
                                setDbFilters(
                                  prev => ({
                                    ...prev,
                                    userLocalId: null,
                                    date: null,
                                  }),
                                  getReport(page),
                                ),
                              )
                              .finally(() => setFetching(false))
                          } else {
                            setDbFilters(prev => ({
                              ...prev,
                              userLocalId: null,
                              date: null,
                            }))
                          }
                        },
                      )
                    }
                  } else global.modal.alert('Data e Municipio é obrigatorio')
                }}
                disabled={downloading}
              />
            </Field>
          </div>
        </div>
        {fetching && (
          <div className="blockui-overlay">
            <div className="blockui" />
          </div>
        )}
      </div>
      {fetching ? (
        <div className="kt-portlet__body kt-pb-0 position-relative">
          <div className="row">
            <LoadingCard />
          </div>
        </div>
      ) : (
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div
            className={classNames(
              'report-irregular kt-datatable kt-datatable--default kt-datatable--brand',
              {
                'kt-datatable--loaded': !fetching || items?.length > 0,
                'table-loading': fetching,
              },
            )}
          >
            <table className="kt-datatable__table">
              <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                  <th className="kt-datatable__cell">
                    <span>MES/ANO</span>
                  </th>
                  <th className="kt-datatable__cell">
                    <span>Municipio</span>
                  </th>
                  <th className="kt-datatable__cell">
                    <span>Qtde Importados</span>
                  </th>
                  <th className="kt-datatable__cell">
                    <span>Qtde Não Encontrados</span>
                  </th>
                  <th className="kt-datatable__cell">
                    <span>Importado em</span>
                  </th>
                  <th>
                    <span>Ações</span>
                  </th>
                </tr>
              </thead>
              <tbody className="kt-datatable__body">
                {items?.map((i, index) => (
                  <tr key={index} className="kt-datatable__row">
                    <td className="kt-datatable__cell">
                      <div>{i.createdAt}</div>
                    </td>
                    <td className="kt-datatable__cell">
                      <div>{i.localName}</div>
                    </td>
                    <td className="kt-datatable__cell">
                      <div>{i.countImported}</div>
                    </td>
                    <td className="kt-datatable__cell">
                      <div>{i.notFound?.length}</div>
                    </td>
                    <td className="kt-datatable__cell">
                      <div>{toLocaleDateTime(i.importedAt)}</div>
                    </td>
                    <td>
                      <Button
                        icon="fas fa-file-alt"
                        title="Relatorio Importação"
                        onClick={() => {
                          handleImportDownload({ date: i.createdAt, userLocalId: i.userLocalId })
                          getReport(page)
                        }}
                        disabled={downloading}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              {items?.length > 0 && (
                <tfoot className="kt-datatable__foot">
                  <tr className="kt-datatable__row">
                    <td className="kt-datatable__cell" />
                    <td className="kt-datatable__cell kt-align-center">{items?.length}</td>
                    <td className="kt-datatable__cell" />
                    <td className="kt-datatable__cell" />
                    <td className="kt-datatable__cell" />
                    <td className="kt-datatable__cell" />
                    <td className="kt-datatable__cell" />
                  </tr>
                </tfoot>
              )}
            </table>

            {items?.length === 0 && (
              <div className="kt-datatable--error">Nenhum item foi encontrado.</div>
            )}
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
              <Pagination current={page} total={footer.pages} changePage={setPage} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
