import React from 'react'

export const Logo = ({ transparency }) => (
  <img
    alt="Sintrapp"
    src={
      transparency
        ? require('../../assets/img/logoTransparente.png')
        : require('../../assets/img/logo.png')
    }
  />
)
